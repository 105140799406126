import React, { memo, useState, useEffect } from 'react';
import { Link, useParams, useHistory, Prompt } from 'react-router-dom';

import { Row, Col, Image, Button, Form, NavDropdown } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { toast } from 'react-toastify';

import '../css/viewUserDetails.css';
import { trackPromise } from 'react-promise-tracker';
import { Desktop, Mobile } from '../../media-queries/mediaQueries';

import ConfirmAlertModal from '../../../components/ConfirmAlertModal/ConfirmAlertModal';
import Toaster from '../../../components/Toaster';
import Ellipses from '../../../components/Ellipses';

import { DateConvert } from '../../../utils/TimesheetManagement';
import { validatePermission } from '../../../utils/UserManagement';
import { useAuthContext } from '../../../contexts/user-context';
import { createCity, updateCityById, getCityById, deleteCityById } from '../../../services/city-service';
import { limitDecimalPlaces } from '../../../utils/helper';

// const animatedComponents = makeAnimated();

const ManageCities = memo(() =>
{
    const { authUser } = useAuthContext();
    const history = useHistory();

    const { cityId } = useParams();
    const isAddMode = !cityId;
    const [isAPICalled, setIsAPICalled] = useState(false);

    const [city, setCity] = useState(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const [isSubmitSuccessful, setisSubmitSuccessful] = useState(false);

    const validationSchema = yup.object().shape({
        cityname: yup.string().required('City name is required.'),
        non_billable_rate: yup.number().transform((cv, ov) => {
            return ov === '' ? undefined : cv;
        }).min(0).required('Non Billable Rate is required.'),
        basic_rate: yup.number().transform((cv, ov) => {
            return ov === '' ? undefined : cv;
        }).min(0).required('Basic Rate is required.'),
        prime_rate: yup.number().transform((cv, ov) =>
        {
            return ov === '' ? undefined : cv;
        }).min(0).required('Prime Rate is required.'),
        special_rate: yup.number().transform((cv, ov) =>
        {
            return ov === '' ? undefined : cv;
        }).min(0).required('Special Rate is required.'),
        holding_fee: yup.number().transform((cv, ov) =>
        {
            return ov === '' ? undefined : cv;
        }).min(0).required('Holding Fee is required.'),
        empl_share: yup.number().transform((cv, ov) =>
        {
            return ov === '' ? undefined : cv;
        }).min(0).required('EMPL Share Fee is required.'),
        status: yup.string().required('Status is required').default('active')
    });

    const methods = useForm({
            resolver: yupResolver(validationSchema),
            mode: 'onChange',
            shouldFocusError: true,
        });
    const { handleSubmit, register,
        formState: { errors, isSubmitting, dirtyFields, },
        reset, } = methods;        

    async function getCityDetails()
    {
        try
        {
            const response = await trackPromise(getCityById(cityId));
            setCity(response.data.data.city);
            reset({...response.data.data.city});
            setIsAPICalled(true);
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    }

    useEffect(() =>
    {
        if (!isAddMode)
        {
            getCityDetails();
        }
        else
        {
            setIsAPICalled(true);
        }
    }, [isAddMode]);    

    const handleReset = () =>
    {
        reset({});
    }

    const onSubmit = async (data) =>
    {
        try
        {
            // if (isValid)
            // {
            if (isAddMode)
            {
                const response = await trackPromise(createCity(data));
                if (response.status === 201)
                {
                    toast.dark(<Toaster
                        icon="notify"
                        message={response.data.message}
                    />);                    
                    setisSubmitSuccessful(true);
                    history.push(`/settings/viewCity/${response.data.data.city._id}`);                    
                }
            }
            else
            {
                const response = await trackPromise(
                  updateCityById(cityId, data)
                );
                if (response.status === 200)
                {
                    toast.dark(<Toaster
                        icon="notify"
                        message={response.data.message}
                    />);
                    setisSubmitSuccessful(true);
                    history.push(`/settings/viewCity/${response.data.data.city._id}`);                    
                }
            }
            
            // }
        } catch (error)
        {            
            if (error.response?.data?.data?.error?.errors?.cityname)
            {
                toast.dark(
                    <Toaster
                        icon="warning"
                        message={`${error.response.data.data.error.errors.cityname.message}`}
                    />,
                    { autoClose: 5000, hideProgressBar: true }
                );
            }
            else
            {
                toast.dark(
                    <Toaster
                        icon="error"
                        message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                    />
                );
            }
        }
    }

    const updateCityStatus = async (status) =>
    {
        try
        {
            const body = {
                status,
            };
            setIsAPICalled(false);
            const response = await trackPromise(updateCityById(cityId, body));
            setCity(response.data.data.city);
            if (response.status === 200)
            {
                toast.dark(<Toaster
                    icon="notify"
                    message={response.data.message}
                />);
                history.push(`/settings/viewCity/${response.data.data.city._id}`);
            }
            setIsAPICalled(true);
        } catch (error)
        {            
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
    };

    const onDeleteCity = async () =>
    {
        try
        {
            const response = await trackPromise(deleteCityById(cityId));
            toast.dark(
                <Toaster icon="notify" message={`${response.data.message}`} />,
                { autoClose: 3000, hideProgressBar: true }
            );
        } catch (error)
        {
            toast.dark(
                <Toaster
                    icon="error"
                    message={error?.response?.data?.message ?? 'Something went wrong!!!'}
                />
            );
        }
        finally
        {
            setShow(false);
            history.replace('/settings/cities');
        }
    };

    const SettingsDropdown = () =>
    {
        return (
            <div>
                <NavDropdown
                    title={<Ellipses />}
                    id="collasible-nav-dropdown"
                >
                    {city.status === 'active' ? (
                        <NavDropdown.Item
                            disabled={!validatePermission(authUser.user.role.permissions, 'city', 'edit')}
                            onClick={() =>
                            {
                                updateCityStatus('inactive')
                            }}>Deactivate</NavDropdown.Item>
                    ) : ('')}
                    {city.status === 'inactive' ? (
                        <NavDropdown.Item
                            disabled={!validatePermission(authUser.user.role.permissions, 'city', 'edit')}
                            onClick={() =>
                            {
                                updateCityStatus('active')
                            }}>Activate</NavDropdown.Item>
                    ) : ('')}
                    <NavDropdown.Item
                        onClick={handleShow}
                        disabled={!validatePermission(authUser.user.role.permissions, 'city', 'delete')}
                    >
                        Delete
                    </NavDropdown.Item>
                    
                </NavDropdown>
            </div>
        );
    };

    return isAPICalled ? (
        <div>
            <div className="sticky-page-header">
                <div className="container">
                    <Desktop>
                        <Row className="ml-5">
                            <Col md="1">
                                <Link to="/settings/cities">
                                    <a
                                        onKeyPress={() => { }}
                                        role="link"
                                        tabIndex="0"
                                    // onClick={onHandleBack}
                                    >
                                        <Image
                                            className="imageArrow"
                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                            fluid
                                        />
                                    </a>
                                </Link>
                            </Col>
                            <Col md="8" classname="pl-0">
                                {
                                    isAddMode ? (
                                        <h5 className="mt-1">
                                            Untitled City
                                        </h5>) : (
                                        <>
                                            <h5 className="mb-0">
                                                <strong>{city.cityname}</strong>
                                            </h5>
                                            <p className="txt-light-secondary mb-0">Last Updated on {DateConvert(city.updatedAt)}</p>
                                        </>
                                    )
                                }
                            </Col>
                            <Col md="1" className="pr-0 ml-5">
                                <Button
                                    variant="default"
                                    onClick={handleReset}
                                    className="float-right btn-ftc-border-primary"
                                >
                                    Reset
                                </Button>
                            </Col>
                            {
                                !isAddMode ? (
                                <Col md="1">
                                    <div className="ml-0">
                                    <SettingsDropdown />
                                    </div>
                                </Col>
                                ) : ('')
                            }
                        </Row>
                    </Desktop>
                    <Mobile>
                        <div className="container">
                            <Row className="pb-0">
                                <Col xs="1" className="p-0">
                                    <Link to="/settings/cities">
                                        <Image
                                            className="imageArrowMobile mt-1"
                                            src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                                            fluid
                                        />
                                    </Link>
                                </Col>
                                <Col xs="8" className="mt-1">
                                    {
                                        isAddMode ? (
                                            <h6 className="m-auto pb-3"><strong>Untitled City</strong> </h6>) : (
                                            <>
                                                <h6>
                                                    <strong>{city.cityname}</strong>
                                                </h6>
                                                <p className="txt-light-secondary">Last Updated on {DateConvert(city.updatedAt)}</p>
                                            </>
                                        )
                                    }
                                </Col>
                                <Col xs="1">
                                    <Image
                                        onClick={handleReset}
                                        className="ftc-logo-24  mt-2"
                                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-reset.svg`}
                                    />
                                </Col>
                                {
                                !isAddMode ? (
                                    <Col xs="1">
                                    <div>
                                        <SettingsDropdown />
                                    </div>
                                    </Col>
                                ) : ('')
                                }
                            </Row>
                        </div>
                    </Mobile>
                </div>
            </div>
            <Desktop>
                <div className="container-top-8" />
            </Desktop>
            <Mobile>
                <div className="container-top-20" />
            </Mobile>
            <div className="container">
                <FormProvider {...methods}>
                    <Form
                        // onSubmit={handleSubmit(onSubmit)}
                    >
                    <div>
                        <Row>
                            <Col md="8" className="mx-auto">
                                <div>
                                    <Row>
                                        <Col md="12">
                                            <h5><strong>City Rates</strong></h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="pb-2">
                                            <Form.Group controlId="formBasicEmail">
                                                <label className="txt-light-secondary">Select City</label>
                                                {/* <Select
                                                    name=''
                                                    isMulti={false}
                                                    closeMenuOnSelect
                                                    components={animatedComponents}
                                                    options='roles'
                                                    getOptionLabel=''
                                                    getOptionValue=''
                                                    value=''
                                                onChange={(option) => {
                                                    setSelectedRole(option);
                                                }}
                                                onBlur={() => {
                                                    onBlurRole();
                                                }}
                                                /> */}
                                                <Form.Control
                                                    {...register('cityname')}
                                                    type="text"
                                                    placeholder="Enter City"
                                                />
                                                {errors.cityname && (
                                                    <p className="text-danger">
                                                        {errors.cityname.message}
                                                    </p>
                                                )}
                                            </Form.Group>

                                        </Col>                                    
                                        <Col md="6" className="pb-2">
                                            <Form.Group controlId="formBasicEmail">
                                                <label className="txt-light-secondary">Non Billable Rate</label>
                                                <Form.Control
                                                    {...register('non_billable_rate')}
                                                    type="number"
                                                    min='0'
                                                        step='0.01'
                                                        pattern='/^[0-9\b]+$/'
                                                    placeholder="0"
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'e' || e.key === 'E') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onInput={(e) => limitDecimalPlaces(e, 2)}
                                                />
                                                {errors.non_billable_rate && (
                                                    <p className="text-danger">
                                                        {errors.non_billable_rate.message}
                                                    </p>
                                                )}
                                            </Form.Group>

                                        </Col>
                                        <Col md="6" className="pb-2">

                                            <Form.Group controlId="formBasicEmail">
                                                <label className="txt-light-secondary">Basic Rate</label>
                                                <Form.Control
                                                    {...register('basic_rate')}
                                                    type="number"
                                                    min='0'
                                                    pattern='/^[0-9\b]+$/'
                                                        step='0.01'
                                                    placeholder="0"
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'e' || e.key === 'E') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onInput={(e) => limitDecimalPlaces(e, 2)}
                                                />
                                                {errors.basic_rate && (
                                                    <p className="text-danger">
                                                        {errors.basic_rate.message}
                                                    </p>
                                                )}
                                            </Form.Group>

                                        </Col>                                    
                                        <Col md="6" className="pb-2">

                                            <Form.Group controlId="formBasicEmail">
                                                <label className="txt-light-secondary">Special Rate</label>
                                                <Form.Control
                                                    {...register('special_rate')}
                                                    type="number"
                                                    min='0'
                                                    pattern='/^[0-9\b]+$/'
                                                        step='0.01'
                                                    placeholder="0"
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'e' || e.key === 'E') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onInput={(e) => limitDecimalPlaces(e, 2)}
                                                />
                                                {errors.special_rate && (
                                                    <p className="text-danger">
                                                        {errors.special_rate.message}
                                                    </p>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md="6" className="pb-2">

                                            <Form.Group controlId="formBasicEmail">
                                                <label className="txt-light-secondary">Prime Rate</label>
                                                <Form.Control
                                                    {...register('prime_rate')}
                                                    type="number"
                                                    min='0'
                                                    pattern='/^[0-9\b]+$/'
                                                        step='0.01'
                                                    placeholder="0"
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'e' || e.key === 'E') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onInput={(e) => limitDecimalPlaces(e, 2)}
                                                />
                                                {errors.prime_rate && (
                                                    <p className="text-danger">
                                                        {errors.prime_rate.message}
                                                    </p>
                                                )}
                                            </Form.Group>

                                        </Col>                                    
                                        <Col md="6" className="pb-2">
                                            <Form.Group controlId="formBasicEmail">
                                                <label className="txt-light-secondary">Holding Fee</label>
                                                <Form.Control
                                                    {...register('holding_fee')}
                                                    type="number"
                                                    min='0'
                                                    pattern='/^[0-9\b]+$/'
                                                        step='0.01'
                                                    placeholder="0"
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'e' || e.key === 'E') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onInput={(e) => limitDecimalPlaces(e, 2)}
                                                />
                                                {errors.holding_fee && (
                                                    <p className="text-danger">
                                                        {errors.holding_fee.message}
                                                    </p>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md="6" className="pb-2">
                                            <Form.Group controlId="formBasicEmail">
                                                <label className="txt-light-secondary">EMPL Share</label>
                                                <Form.Control
                                                    {...register('empl_share')}
                                                    type="number"
                                                    min='0'
                                                    pattern='/^[0-9\b]+$/'
                                                        step='0.01'
                                                    placeholder="0"
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'e' || e.key === 'E') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onInput={(e) => limitDecimalPlaces(e, 2)}
                                                />
                                                {errors.empl_share && (
                                                    <p className="text-danger">
                                                        {errors.empl_share.message}
                                                    </p>
                                                )}
                                            </Form.Group>

                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="border-bottom" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                                <button
                                                    // type="submit"
                                                    type="button"
                                                    onClick={handleSubmit(onSubmit)}
                                                    disabled={isSubmitting} className="btn btn-ftc-primary float-right">
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Form>
                </FormProvider>
            </div>
            {show && <ConfirmAlertModal
                show={show}
                handleClose={handleClose}
                title='Delete City'
                description='Deleting this City will erase all data inside this record.
                Are you sure you want to delete this City?'
                callback={onDeleteCity}
                action='Delete it'
                closeAction='Cancel'
            />}

            <Prompt
                when={isSubmitSuccessful ? false : Object.keys(dirtyFields).length}                
                message='You have unsaved changes, are you sure you want to leave?'
            />
        </div>
    ) : ('')
});

export default ManageCities;

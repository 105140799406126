import API from './api-service';
import API_ENDPOINTS from '../constants/api_endpoints';

const getAllPeriods = () =>
{
    return API.get(API_ENDPOINTS.PERIOD.GetAllPeriod);
};

const getPeriodsByYear = (year, date) =>
{
    return API.get(`${API_ENDPOINTS.PERIOD.GetAllPeriod}/byYearSelection/${year}/${date}`);
}

const getAllPeriodYears = () => {
    return API.get(`${API_ENDPOINTS.PERIOD.GetAllPeriodYears}`);
}

const getPeriodsLtYear = (year) =>
{
    return API.get(`${API_ENDPOINTS.PERIOD.GetAllPeriodsForSelect}/${year}`);
}

const getPeriodsByYearAndDate = (year, date) =>
{    
    return API.get(`${API_ENDPOINTS.PERIOD.GetAllPeriod}/byYearAndDate/${year}/${date}`);
}

const getPeriodByDate = (body) => {
    return API.post(API_ENDPOINTS.PERIOD.GetPeriodByDate, body);
};

const createPeriodForYear = (body) =>
{
    return API.post(API_ENDPOINTS.PERIOD.GetAllPeriod, body);
};

const deletePeriodsByYear = (year) =>
{
    return API.del(`${API_ENDPOINTS.PERIOD.GetAllPeriod}/${year}`);
};

export
{
    createPeriodForYear,
    getAllPeriods,
    getAllPeriodYears,
    getPeriodsLtYear,
    getPeriodByDate,
    getPeriodsByYear,
    getPeriodsByYearAndDate,
    deletePeriodsByYear,
};
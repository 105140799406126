import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { Button, Col, Image, Modal, Row } from 'react-bootstrap';
import { Desktop, Mobile } from '../media-queries/mediaQueries';

const ReimbursementFilters = memo(
    ({
        reimbursementFilters,
        clearAll,
        show,
        handleClose,
        applyChanges = () => { },
        handleResetFilters = () => { },
    }) => {
        const [filterOptions, setFilterOption] = useState('period');

        const onClearAll = () => {
            clearAll();
        };

        const removePeriod = () => {
            handleResetFilters('period');
        };

        const removeDateRange = () => {
            handleResetFilters('submissionDate');
        };

        const removeReimbursementReason = () => {
            handleResetFilters('reimbursementReason');
        };

        const removeTotalAmount = () => {
            handleResetFilters('totalAmount');
        };


        const filterDetailsByOption = () => {
            switch (filterOptions) {
                case 'period':
                    return (
                        <div>
                            <Row>
                                <Col md="3" xs="6">
                                    <label>Period</label>
                                </Col>
                                <Col md="9" xs="6">
                                    <a
                                        className="txt-primary float-right"
                                        role="button"
                                        onClick={removePeriod}
                                        onKeyDown={() => { }}
                                        tabIndex={-1}
                                    >
                                        Deselect All
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                {reimbursementFilters("periodYear")}
                                {reimbursementFilters("period")}
                            </Row>
                        </div>
                    );

                case 'date':
                    return (
                        <div>
                            <Row>
                                <Col lg="8" md="8" xs="8">
                                    <label>Submission Date Range</label>
                                </Col>
                                <Col lg="4" md="4" xs="4">
                                    <a
                                        className="txt-primary float-right"
                                        role="button"
                                        onClick={removeDateRange}
                                        onKeyDown={() => { }}
                                        tabIndex={-1}
                                    >
                                        Reset
                                    </a>
                                </Col>
                            </Row>
                            {reimbursementFilters("date")}
                        </div>
                    );

                case 'reason':
                    return (
                        <div>
                            <Row>
                                <Col md="3" xs="6">
                                    <label>Reason</label>
                                </Col>
                                <Col md="9" xs="6">
                                    <a
                                        className="txt-primary float-right"
                                        role="button"
                                        onClick={removeReimbursementReason}
                                        onKeyDown={() => { }}
                                        tabIndex={-1}
                                    >
                                        Reset
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                {reimbursementFilters("reason")}
                            </Row>
                        </div>
                    );

                case 'amount':
                    return (
                        <div>
                            <Row>
                                <Col md="3" xs="6">
                                    <label>Amount</label>
                                </Col>
                                <Col md="9" xs="6">
                                    <a
                                        className="txt-primary float-right"
                                        role="button"
                                        onClick={removeTotalAmount}
                                        onKeyDown={() => { }}
                                        tabIndex={-1}
                                    >
                                        Reset
                                    </a>
                                </Col>
                            </Row>
                            {reimbursementFilters("amount")}
                        </div>
                    );
                default:
            }
            return null;
        }

        const desktopFilterOption = () => {
            const renderFilterOptions = [
                { value: 'period', title: 'Period' },
                { value: 'date', title: 'Submission Date Range' },
                { value: 'reason', title: 'Reason' },
                { value: 'amount', title: 'Amount' },
            ];
            return renderFilterOptions.map((option) => (
                <li
                    key={option.value} // Make sure to use a unique key for each item
                    className={`${filterOptions === `${option.value}` ? 'mb__modal_filter_active' : ''} modal__li`}
                    onClick={() => setFilterOption(option.value)}
                    role="menuitem"
                    onKeyPress={() => { }}
                >
                    <span className="modal__list__padding">{option.title}</span>
                    <Image
                        className="icon_arrow float-right"
                        src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-arrow.svg`}
                        fluid
                    />
                </li>
            ));
        };

        const mobileFilterOption = () => {

            const renderFilterOptions = [
                { value: 'period', title: 'Period' },
                { value: 'date', title: 'Submission Date Range' },
                { value: 'reason', title: 'Reason' },
                { value: 'amount', title: 'Amount' },
            ];

            return renderFilterOptions.map((option) => (
                <li
                    key={option.value}
                    className={`${filterOptions === `${option.value}` ? 'mb__modal_filter_active' : ''
                        } mb__modal__li`}
                    onClick={() => setFilterOption(option.value)}
                    role="menuitem"
                    onKeyPress={() => { }}
                >
                    <span className="modal__list__padding">{option.title}</span>{' '}
                </li>
            ));
        };

        return (
            <Modal
                className='modal-container'
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                centered
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="modal__row">
                        <Desktop>
                            <Col
                                md="3"
                                xs="4"
                                className="modal__Col_3_Padding border__right sticky-top"
                            >
                                <ul className="modal__ul">
                                    {desktopFilterOption()}
                                </ul>
                            </Col>
                        </Desktop>
                        <Mobile>
                            <Col
                                md="3"
                                xs="4"
                                className="modal__Col_3_Padding border__right  modal-filters"
                            >
                                <ul className="modal__ul">
                                    {mobileFilterOption()}
                                </ul>
                            </Col>
                        </Mobile>
                        <Col md="9" xs="8">
                            {filterDetailsByOption()}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="default"
                        onClick={() => {
                            onClearAll();
                            // handleClose();
                        }}
                    >
                        Clear All
                    </Button>
                    <Button
                        variant="default"
                        className="btn-ftc-border-primary"
                        onClick={() => {
                            applyChanges();
                        }}
                    >
                        Apply Changes
                    </Button>
                </Modal.Footer>
            </Modal >
        );
    }
);

export default ReimbursementFilters;

ReimbursementFilters.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    applyChanges: PropTypes.func.isRequired,
    clearAll: PropTypes.func.isRequired,
    reimbursementFilters: PropTypes.func.isRequired,
};

import React, { memo, useEffect, useState, useRef } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  Row,
  Image,
  Card,
  InputGroup,
} from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { trackPromise } from 'react-promise-tracker';

import { toast } from 'react-toastify';

import { getAllNonBillablesByAccess } from '../../../services/non_billable.service';

import {
  calculateTotalTime,
  getPaymentStatusLabel,
  holdingFeeNumbers,
} from '../../../utils/TimesheetManagement';
import { useAuthContext } from '../../../contexts/user-context';
import Toaster from '../../../components/Toaster';
import IconTrue from '../../../assets/images/icon-true.svg';
import { limitDecimalPlaces } from '../../../utils/helper';

const ChargeDetails = memo(
  ({ formContent, isAddMode, paymentStatus, showPaymentConfirmation }) => {
    const { authUser } = useAuthContext();
    const enableStatusUpdate =
      authUser.user.role.rolename === 'Admin' ||
      authUser.user.role.rolename === 'Manager';

    const chooseRateRef = useRef();
    const nonBillableRef = useRef();

    const [holdingFeeNum, setholdingFeeNum] = useState(
      formContent?.three?.holding_fee_number ||
        formContent?.three?.holding_fee_number === 0
        ? holdingFeeNumbers[formContent.three.holding_fee_number]
        : holdingFeeNumbers[0]
    );
    const ratePlans = [
      { label: 'Non-Billable', value: 'non_billable_rate' },
      { label: 'Basic Rate', value: 'basic_rate' },
      { label: 'Prime Rate', value: 'prime_rate' },
      { label: 'Special Rate', value: 'special_rate' },
    ];
    const [holdingFee, setholdingFee] = useState(0);
    const [selectedRatePlan, setselectedRatePlan] = useState(
      formContent?.three?.choose_rate ? formContent.three.choose_rate : null
    );
    const [nonBillables, setnonBillables] = useState([]);
    const [selectedReason, setselectedReason] = useState(
      formContent?.three?.non_billable_reason?._id
        ? formContent.three.non_billable_reason
        : null
    );

    const [ratePrice, setRatePrice] = useState(0);

    const [totalDue, setTotalDue] = useState(0);

    const {
      register,
      control,
      formState: { errors, isSubmitted },
      watch,
      setValue,
    } = useFormContext();
    const tip = Number.parseFloat(watch('tip', 0));
    const adjustment = Number.parseFloat(watch('adjustment', 0));

    const isOfficeUser = authUser.user.role.rolename !== 'Office';

    const isAdjustmentEditable =
      authUser.user.role.rolename === 'Chef' && !isAddMode;

    async function fetchAllNonBillables() {
      try {
        const role = formContent?.one?.employee?.role._id;
        const response = await trackPromise(getAllNonBillablesByAccess(role));
        setnonBillables(response.data.data.non_billables);
      } catch (error) {
        // Cache Data in Local Storage
        const apiOfflineNonBillableData = JSON.parse(
          localStorage.getItem('nonBillables')
        );
        setnonBillables(apiOfflineNonBillableData);
        console.log(error);
        toast.dark(
          <Toaster
            icon="error"
            message={
              error?.response?.data?.message ?? 'Something went wrong!!!'
            }
          />
        );
      }
    }

    useEffect(() => {
      fetchAllNonBillables();
      if (authUser.user.role.rolename === 'Office') {
        setselectedRatePlan({
          label: 'Non-Billable',
          value: 'non_billable_rate',
        });
      }
    }, []);

    useEffect(() => {
      if (errors?.choose_rate) {
        window.scrollTo({
          behavior: 'smooth',
          top: chooseRateRef.current.offsetTop,
        });
      }
      if (errors?.non_billable_reason) {
        window.scrollTo({
          behavior: 'smooth',
          top: nonBillableRef.current.offsetTop,
        });
      }
    }, [errors]);

    useEffect(() => {
      if (holdingFeeNum) {
        const currentFee = formContent.one.holding_fee * holdingFeeNum.value;
        setholdingFee(currentFee);
        setValue('holding_fee_number', holdingFeeNum.value, {
          shouldDirty: true,
          shouldValidate: true,
        });
        setValue('current_holding_fee', currentFee, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    }, [holdingFeeNum]);

    useEffect(() => {
      if (selectedRatePlan) {
        setRatePrice(formContent.one[selectedRatePlan.value]);
        setValue('rate', formContent.one[selectedRatePlan.value], {
          shouldDirty: true,
          shouldValidate: true,
        });
        setValue('choose_rate', selectedRatePlan, {
          shouldDirty: true,
          shouldValidate: true,
        });
      } else {
        setValue('choose_rate', null, {
          shouldDirty: false,
          shouldValidate: true,
        });
      }
      if (selectedRatePlan && selectedRatePlan.value !== 'non_billable_rate') {
        setselectedReason(null);
        setValue('non_billable_reason', undefined, {
          shouldDirty: false,
          shouldValidate: true,
        });
      }
    }, [selectedRatePlan]);

    useEffect(() => {
      if (selectedReason) {
        setValue('non_billable_reason', selectedReason, {
          shouldDirty: true,
          shouldValidate: true,
        });
      } else {
        setValue('non_billable_reason', null, {
          shouldDirty: false,
          shouldValidate: true,
        });
      }
    }, [selectedReason]);

    useEffect(() => {
      const totalTime = !Number.isNaN(formContent.two.totalTime)
        ? calculateTotalTime(formContent.two.totalTime)
        : 0;
      // console.log(calculateTotalTime(formContent.two.totalTime));
      const tempTip = !Number.isNaN(tip) ? tip : 0;
      const tempAdjustment = !Number.isNaN(adjustment) ? adjustment : 0;
      const total = Number.parseFloat(
        totalTime * ratePrice +
        Number.parseFloat(holdingFee) +
        Number.parseFloat(tempTip) +
        Number.parseFloat(tempAdjustment)
      );
      setTotalDue(total.toFixed(2));
      setValue('totalDue', total.toFixed(2), {
        shouldDirty: false,
        shouldValidate: true,
      });
      // console.log({ ratePrice, holdingFee, tip, adjustment, total });
    }, [ratePrice, holdingFee, tip, adjustment]);

    const displayPaymentStatus = () => {
      switch (paymentStatus) {
        case 'paid':
          return (
            <Alert variant="succss">
              <Col md="12">
                Payment Status
                <span className="float-right">
                  Paid
                  <Image
                    className="float-right"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-check.svg`}
                    fluid
                  />
                </span>
              </Col>
            </Alert>
          );

        case 'pending':
          return (
            <Alert variant="warning">
              <Col md="12">
                Payment Status
                <span className="float-right">
                  Pending
                  <Image
                    className="float-right"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-alert.svg`}
                    fluid
                  />
                </span>
              </Col>
            </Alert>
          );

        default:
          return null;
      }
    };

    return (
      <div className="container">
        <Row>
          <Col md="8" className="mx-auto p-0">
            <Row>
              {isOfficeUser && (
                <Col md="6" xs="12" className="pb-3">
                  <Form.Group
                    controlId="exampleForm.SelectCustom"
                    className="mb-2"
                  >
                    <Form.Label className="txt-light-secondary">
                      Holding Fee Number
                    </Form.Label>
                    <Controller
                      control={control}
                      name="holding_fee_number"
                      render={({ field: { name } }) => (
                        <Select
                          options={holdingFeeNumbers}
                          name={name}
                          isMulti={false}
                          closeMenuOnSelect
                          onChange={setholdingFeeNum}
                          value={holdingFeeNum}
                        />
                      )}
                    />
                    {errors.holding_fee_number && (
                      <p className="text-danger">
                        {errors.holding_fee_number.message}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              )}

              {authUser.user.role.rolename !== 'Office' && (
                <Col md="6" xs="12">
                  <Form.Group controlId="exampleForm.SelectCustomTip">
                    <Form.Label className="txt-light-secondary">Tip</Form.Label>
                    <Form.Control
                      {...register('tip')}
                      type="number"
                      min="0"
                      step="1"
                      placeholder="0"
                      onInput={(e) => limitDecimalPlaces(e, 2)}
                      // onChange={(e) => setTip(Number.parseFloat(e.target.value).toFixed(2))}
                      onKeyPress={(e) => {
                        // Prevent the entry of the character 'e'
                        if (e.key === 'e' || e.key === 'E') {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Group>
                  {errors.tip && (
                    <p className="text-danger">{errors.tip.message}</p>
                  )}
                </Col>
              )}

              {authUser.user.role.rolename !== 'Office' && (
                <Col md="6" xs="12">
                  <label>
                    <span className="txt-light-secondary">Holding Fee:</span>
                    <span className="txt-primary">
                      <strong> ${holdingFee.toFixed(2)}</strong>
                    </span>
                  </label>
                </Col>
              )}
            </Row>
            {isOfficeUser && (
              <Row ref={chooseRateRef}>
                <Col md="6" xs="12">
                  <label>
                    <span className="txt-light-secondary">
                      Choose Rate <span className="txt-primary">*</span>
                    </span>
                  </label>
                </Col>
                <Col md="6" xs="12" />
                {ratePlans.map((rate) => {
                  return (
                    <Col md="3" key={rate.value}>
                      <Form.Group>
                        <Button
                          variant="default"
                          className={
                            selectedRatePlan?.value === rate.value
                              ? 'ftc-radiobutton-border-checked pb-0 btn-border-round'
                              : 'ftc-radiobutton-border-unchecked pb-0 btn-border-round'
                          }
                        >
                          <input
                            type="radio"
                            className="ftc-radiobutton-secondary "
                            id={rate.value}
                            name="ratePlans"
                            onChange={() => {
                              setselectedRatePlan(rate);
                            }}
                            checked={selectedRatePlan?.value === rate.value}
                          />
                          <Form.Label
                            htmlFor={rate.value}
                            className="position-relative ml-4 pl-2"
                          >
                            {rate.label}
                          </Form.Label>
                        </Button>
                      </Form.Group>
                    </Col>
                  );
                })}
                {isSubmitted && errors.choose_rate && (
                  <p className="text-danger ml-3">
                    {errors.choose_rate.message}
                  </p>
                )}
              </Row>
            )}
            <Row ref={nonBillableRef}>
              {/* Display Non Billable section only if non_billable_Reason is selected */}
              {selectedRatePlan?.value === 'non_billable_rate' ? (
                <>
                  <Col md="6" xs="12">
                    <label>
                      <span className="txt-light-secondary">
                        Non-Billable Reason{' '}
                        <span className="txt-primary">*</span>{' '}
                      </span>
                    </label>
                  </Col>
                  <Col md="6" xs="12" />
                  {nonBillables.length ? (
                    nonBillables.map((billable) => {
                      return (
                        <Col md="3">
                          <Form.Group>
                            <Button
                              variant="default"
                              className={
                                selectedReason?._id === billable._id
                                  ? 'ftc-radiobutton-border-checked pb-0 btn-border-round'
                                  : 'ftc-radiobutton-border-unchecked pb-0 btn-border-round'
                              }
                            >
                              <input
                                type="radio"
                                className="ftc-radiobutton-secondary"
                                id={billable._id}
                                name="nbreason"
                                checked={selectedReason?._id === billable._id}
                                disabled={
                                  selectedRatePlan?.value !==
                                  'non_billable_rate'
                                }
                                onChange={() => {
                                  setselectedReason(billable);
                                }}
                              />
                              <Form.Label
                                htmlFor={billable._id}
                                className="position-relative ml-4 pl-1"
                              >
                                {billable.title}
                              </Form.Label>
                            </Button>
                          </Form.Group>
                        </Col>
                      );
                    })
                  ) : (
                    <Col md="12">
                      <p>
                        Sorry, Non Billable reasons are not accessible! Please
                        select another Rate Plan.
                      </p>
                    </Col>
                  )}
                  <Col md="6" xs="12" />
                  <Col md="12">
                    {isSubmitted && errors.non_billable_reason && (
                      <p className="text-danger">
                        {errors.non_billable_reason.message}
                      </p>
                    )}
                  </Col>
                </>
              ) : (
                ''
              )}
              <Col md="9" />
              <Col md="6" xs="12">
                <Form.Group controlId="exampleForm.Adjustment">
                  <Form.Label className="txt-light-secondary">
                    Adjustment
                  </Form.Label>
                  <InputGroup>
                    {/* SVG icon inside the input field */}
                    <div style={{ position: 'relative', width: '100%' }}>
                      {formContent.two.isAcknowledged ? (
                        <>
                          <Image
                            src={IconTrue}
                            alt="icon"
                            title={`${formContent?.one?.acknowledgedBy?.username}`}
                            style={{
                              position: 'absolute',
                              right: '10px', // Adjust the right position as needed
                              top: '50%', // Center the icon vertically inside the input field
                              transform: 'translateY(-50%)', // Center the icon vertically inside the input field
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      <Form.Control
                        {...register('adjustment')}
                        type="number"
                        placeholder="0"
                        onInput={(e) => limitDecimalPlaces(e, 2)}
                        readOnly={isAdjustmentEditable}
                        className="pr-4"
                        onKeyPress={(e) => {
                          // Prevent the entry of the character 'e'
                          if (e.key === 'e' || e.key === 'E' || e.key === '+') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </InputGroup>
                  {errors.adjustment && (
                    <p className="text-danger">{errors.adjustment.message}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md="6" xs="12">
                <br />
                <Form.Group className="mt-1" controlId="exampleForm.rateLabel">
                  <div className="label-readonly">
                    <label>Rate</label>
                    <span className="float-right">
                      <strong>
                        <label>$ {ratePrice?.toFixed(2)}</label>
                      </strong>
                    </span>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6" xs="12">
                <div className="label-readonly">
                  <label>Total Due</label>
                  <span className="float-right">
                    <strong>
                      <label>$ {totalDue}</label>
                    </strong>
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="pl-3">
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label className="txt-light-secondary">
                    Comment
                  </Form.Label>
                  <Form.Control
                    {...register('comments')}
                    as="textarea"
                    rows={4}
                  />
                  {errors.comments && (
                    <p className="text-danger">{errors.comments.message}</p>
                  )}
                </Form.Group>
              </Col>

              <Col md="12" className="mt-2 pt-1">
                {isAddMode || !enableStatusUpdate ? (
                  displayPaymentStatus()
                ) : (
                  //   paymentStatus === 'paid' && (
                  //   <Alert variant="succss">
                  //     <Col md="12">
                  //       Payment Status
                  //       <span className="float-right">
                  //         Pending
                  //         <Image
                  //           className="float-right"
                  //           src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-check.svg`}
                  //           fluid
                  //         />
                  //       </span>
                  //     </Col>
                  //   </Alert>
                  // )

                  <Card>
                    <Card.Body className="p-0">
                      {paymentStatus === 'pending' && (
                        <Alert variant="warning" className="pl-0 pr-0">
                          <Col md="12">
                            Payment Status
                            <span className="float-right">
                              {getPaymentStatusLabel(paymentStatus)}
                              <Image
                                className="float-right"
                                src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-alert.svg`}
                                fluid
                              />
                            </span>
                          </Col>
                        </Alert>
                      )}

                      {paymentStatus === 'paid' && (
                        <Alert variant="success" className="pl-0 pr-0">
                          <Col md="12">
                            Payment Status
                            <span className="float-right">
                              {getPaymentStatusLabel(paymentStatus)}
                              {` `}
                              <Image
                                className="float-right"
                                src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-check.svg`}
                                fluid
                              />
                            </span>
                          </Col>
                        </Alert>
                      )}
                    </Card.Body>
                    {/* {
                      enableStatusUpdate ? ( */}
                    <Card.Body className="pt-0 pb-0">
                      <Row className="pt-0">
                        <Col md="8" xs="10" className="pl-0">
                          {paymentStatus === 'pending' && (
                            <label className="mb-0">Mark as Paid</label>
                          )}

                          {paymentStatus === 'paid' && (
                            <label className="mb-0">Marked Paid.</label>
                          )}
                          <p className="txt-light-secondary mb-0">
                            Please note that this action cannot be reversed.
                          </p>
                        </Col>

                        <Col md="4" xs="2" className="pr-0">
                          <Form.Check
                            className="float-right"
                            type="switch"
                            id="custom-switch"
                            checked={paymentStatus === 'paid'}
                            disabled={paymentStatus === 'paid'}
                            onChange={() => {
                              showPaymentConfirmation();
                            }}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                    {/* ) : ('')
                    } */}
                  </Card>
                )}
              </Col>
            </Row>
            <div className="ml-2 mr-2 mt-3 border-bottom" />
          </Col>
        </Row>
      </div>
    );
  }
);

export default ChargeDetails;

ChargeDetails.propTypes = {
  formContent: PropTypes.any.isRequired,
  isAddMode: PropTypes.bool.isRequired,
  paymentStatus: PropTypes.string,
  showPaymentConfirmation: PropTypes.func.isRequired,
};

ChargeDetails.defaultProps = {
  paymentStatus: 'pending',
};

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, Prompt, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// kept for future use
// import { set } from 'idb-keyval';
import { openDB } from 'idb';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Form, Image, NavDropdown, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { trackPromise } from 'react-promise-tracker';
import { v4 as uuidv4 } from 'uuid';
import qs from 'query-string';
import { Desktop, Mobile } from '../media-queries/mediaQueries';
import './css/editTimesheet.css';
import {
  createTimesheet,
  deleteTimesheetById,
  getTimesheetByTimesheetId,
  updatePaymentStatus,
  updateTimesheetById,
} from '../../services/timesheet.service';
import {
  DateConvert,
  checkTimesheetDeletable,
  getRatePlans,
  showTimesheetLastUpdated,
} from '../../utils/TimesheetManagement';
import ConfirmAlertModal from '../../components/ConfirmAlertModal/ConfirmAlertModal';
import Toaster from '../../components/Toaster';
import BasicDetails from './Forms/BasicDetails';
import ChargeDetails from './Forms/ChargeDetails';
import DateTimeDetails from './Forms/DateTimeDetails';

import Ellipses from '../../components/Ellipses';
import { useAuthContext } from '../../contexts/user-context';
import { getUserFlexPayLimit, updateUserByEmployeeId } from '../../services/users-service';
import useNavigator from '../../utils/CustomHooks/useNavigator';
import useQuery from '../../utils/CustomHooks/useQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Basic Details', 'Date & Time', 'Charges'];
}

const EditTimeSheet = () => {
  const classes = useStyles();
  const { authUser } = useAuthContext();

  // custom hook for checking network status
  const isOnline = useNavigator();

  const [activeStep, setActiveStep] = useState(0);
  const [compiledForm, setCompiledForm] = React.useState({});

  const steps = getSteps();
  const URLQuery = useQuery();
  // eslint-disable-next-line no-unused-vars
  const [searchQuery, setSearchQuery] = useState({
    employee: URLQuery.get('employee') ?? '',
    client: URLQuery.get('client') ?? '',
  });

  const { timesheetId } = useParams();
  const [isAPICalled, setIsAPICalled] = useState(false);
  const isAddMode = !timesheetId;

  const [timesheetData, setTimesheet] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDelete, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const [showCityError, setShowCityError] = useState(false);
  const [showTimeError, setshowTimeError] = useState(false);

  const [showPrompt, setshowPrompt] = useState(false);

  const validationSchema = [
    // validation for step1
    yup.object({
      employee: yup
        .mixed('Employee is required!')
        .required('Employee is required.'),
      service_city: yup
        .mixed('Service city is required.')
        .required('Service city is required.')
        .nullable(),
      client: yup.mixed().nullable(),
      non_billable_rate: yup
        .number()
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        .min(0)
        .required('Non Billable Rate is required.'),
      basic_rate: yup
        .number()
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        .min(0)
        .required('Basic Rate is required.'),
      prime_rate: yup
        .number()
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        .min(0)
        .required('Prime Rate is required.'),
      special_rate: yup
        .number()
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        .min(0)
        .required('Special Rate is required.'),
      holding_fee: yup
        .number()
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        .min(0)
        .required('Holding Fee is required.'),
      empl_share: yup
        .number()
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        .min(0)
        .required('EMPL Share Fee is required.'),
    }),
    // validation for step2
    yup.object().shape(
      {
        ts_date: yup.string().required('Please select a date.'),
        arriveTime: yup
          .number()
          .nullable(true)
          .transform((cv, ov) => {
            return ov === '' || Number.isNaN(ov) ? null : cv;
          })
          .when('departureTime', {
            is: (val) => val !== null,
            then: yup
              .number()
              .nullable(true)
              .transform((cv, ov) => {
                return ov === '' || Number.isNaN(ov) ? null : cv;
              })
              .required('Arrive time is required.'),
          })
          .when('flexPayHours', {
            is: (val) => val !== null || val !== "" || val !== "0",
            then: yup
              .number()
              .nullable(true)
              .transform((cv, ov) => {
                return ov === '' || Number.isNaN(ov) ? null : cv;
              })
          }),
        departureTime: yup
          .number()
          .nullable(true)
          .transform((cv, ov) => {
            return ov === '' || Number.isNaN(ov) ? null : cv;
          })
          .when('arriveTime', {
            is: (val) => val !== null,
            then: yup
              .number()
              .nullable(true)
              .transform((cv, ov) => {
                return ov === '' || Number.isNaN(ov) ? null : cv;
              })
              .required('Departure time is required.'),
          })
          .when('flexPayHours', {
            is: (val) => val !== null || val !== "" || val !== "0",
            then: yup
              .number()
              .nullable(true)
              .transform((cv, ov) => {
                return ov === '' || Number.isNaN(ov) ? null : cv;
              })
          }),
        flexPayHours: yup
          .number()
          .nullable(true) // Allow null values
          .transform((cv, ov) => {
            return ov === '' || Number.isNaN(ov) ? null : cv;
          })
          .test('not-zero', 'Value cannot be 0.', (value) => {
            return value !== 0;
          }),
        driveTime: yup
          .number()
          .min(0, 'Drive Time can not be negative!')
          .nullable()
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          }),
        trainer: yup.boolean(),
        totalTime: yup
          .number()
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .required('Total time is required'),
      },
      [['arriveTime', 'departureTime']]
    ),
    // validation for step3
    yup.object({
      holding_fee_number: yup
        .number()
        .default(0)
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        .required('Please select Holding fee number.'),
      tip: yup
        .number('Please enter a valid number!')
        .min(0, 'Tip can not be negative!')
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        .nullable(),
      adjustment: yup
        .number('Please enter a valid number!')
        // .min(0, 'Adjustment can not be negative!')
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        .nullable(),
      current_holding_fee: yup
        .number()
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        .required('Holding fees are required.'),
      choose_rate: yup
        .object({
          label: yup.string(),
          value: yup.string(),
        })
        .nullable()
        .required('Rate plan is required.'),
      non_billable_reason: yup.mixed().when('choose_rate.value', {
        is: 'non_billable_rate',
        then: yup.mixed().required('Non Billable reason is required.'),
      }),
      rate: yup
        .number()
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        .required('Rate is required.'),
      totalDue: yup
        .number()
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        .required('Total due is required.'),
      comments: yup.string().when(['choose_rate.value', 'adjustment'], {
        is: (rate, adjust) =>
          rate === 'non_billable_rate' ||
          (adjust !== undefined && adjust !== null),
        then: yup.string().required('Comments are required.'),
      }),
      // payment_status: yup.string().oneOf(['pending', 'paid']).default('pending')
    }),
  ];

  const currentValidationSchema = validationSchema[activeStep];

  const methods = useForm({
    resolver: yupResolver(currentValidationSchema),
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid, errors, isSubmitting, dirtyFields },
    trigger,
    watch,
    reset,
  } = methods;
  const form = watch();
  const history = useHistory();
  const route = (path, search) => {
    history.push({
      pathname: path,
      search: search || qs.stringify(searchQuery)
    });
  }
  // const myForm = useRef(null);

  const handleNext = async () => {
    const isStepValid = await trigger();

    switch (activeStep) {
      case 0:
        setShowCityError(true);
        break;

      case 1:
        setshowTimeError(true);
        break;

      default:
        break;
    }
    // const checkValidity = myForm.current.checkValidity();
    // console.log({checkValidity, myForm});
    if (isStepValid || isValid) {
      switch (activeStep) {
        case 0:
          setCompiledForm({ ...compiledForm, one: form });
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          break;
        case 1:
          setCompiledForm({ ...compiledForm, two: form });
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          break;
        case 2:
          setCompiledForm({ ...compiledForm, three: form });
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          break;
        default:
          return null;
      }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      switch (activeStep) {
        case 1:
          setCompiledForm({ ...compiledForm, two: form });
          break;
        case 2:
          setCompiledForm({ ...compiledForm, three: form });
          break;
        default:
          return 'not a valid step';
      }
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    if (!isOnline) {
      if (!isAddMode) {
        reset({ uniqueId: timesheetData.uniqueId });
      }
    } else {
      reset({});
    }
    setCompiledForm({
      one: null,
      two: null,
      three: null,
    });
    setShowCityError(false);
    setshowTimeError(false);
  };

  const updateFlexPayLimit = async (employeeId, newFlexPayHours) => {
    try {
      const body = {
        flex_pay_hours_limit: newFlexPayHours,
      };
      const response = await trackPromise(
        updateUserByEmployeeId(employeeId, body)
      );

      if (response.status === 200) {
        toast.dark(<Toaster icon="notify" message={response.data.message} />);
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  };

  async function getUserCurrFlexPayLimit(employeeId) {
    try {
      const response = await trackPromise(getUserFlexPayLimit(employeeId));
      const currLimit = response?.data?.data?.flexPayBalance;
      return currLimit;
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function getFlexPayHoursFromTimesheet() {
    try {
      const response = await trackPromise(
        getTimesheetByTimesheetId(timesheetId)
      );
      const flexPayHours = response?.data?.data?.timesheet?.flexPayHours
      return flexPayHours;
    } catch (error) {
      console.log(error);
    }
  }

  const onSubmitForm = async (data) => {
    try {
      const employeeId = data?.employee?.employeeId;
      const currLimit = await getUserCurrFlexPayLimit(employeeId);

      const currFlexPayHours = Number(data?.flexPayHours);
      let newFlexPayHours;
      if (!isAddMode) {
        const flexPayHoursFromTimesheet = await getFlexPayHoursFromTimesheet();
        if (currFlexPayHours !== flexPayHoursFromTimesheet) {
          const newLimit = currLimit + flexPayHoursFromTimesheet;
          newFlexPayHours = newLimit - currFlexPayHours;
        } else if (currFlexPayHours === flexPayHoursFromTimesheet) {
          newFlexPayHours = currLimit;
        }
      } else {
        newFlexPayHours = currLimit - currFlexPayHours;
      }
      updateFlexPayLimit(employeeId, newFlexPayHours);

      const isStepValid = await trigger();
      if (isStepValid || isValid) {
        const timesheetBody = form;
        // one
        timesheetBody.employee = timesheetBody.employee._id;
        timesheetBody.service_city = timesheetBody?.service_city._id ?? null;
        timesheetBody.client = timesheetBody?.client?._id ?? null;

        timesheetBody.choose_rate = timesheetBody.choose_rate.label;
        timesheetBody.non_billable_reason =
          timesheetBody?.non_billable_reason?._id ?? null;

        // three
        // timesheetBody.totalDue = totalDue

        // after timesheet status is changed to paid it's status won't be changed
        if (isAddMode) {
          timesheetBody.payment_status =
            timesheetBody.totalDue === '0.00' ||
              timesheetBody.non_billable_reason
              ? 'paid'
              : 'pending';
        } else if (!isAddMode && timesheetData.payment_status === 'paid') {
          timesheetBody.payment_status = 'paid';
        } else if (!isAddMode && timesheetData.payment_status === 'pending') {
          timesheetBody.payment_status =
            timesheetBody.totalDue === '0.00' ||
              timesheetBody.non_billable_reason
              ? 'paid'
              : 'pending';
        }

        // call create timesheet API else update

        // currently not implementing for service worker, kept for future source
        // const token = localStorage.getItem('token');
        // set('token', token);

        if (!isOnline) {
          // serviceWorker.registerSync();

          const offlineData = data;
          offlineData.choose_rate = data.choose_rate.label;
          // eslint-disable-next-line no-prototype-builtins
          offlineData.adjustment = data.hasOwnProperty('adjustment') ? data.adjustment : null;
          offlineData.payment_status =
            offlineData.totalDue === '0.00' || timesheetBody.non_billable_reason
              ? 'paid'
              : 'pending';

          // check if isAddMode then create new
          if (isAddMode) {
            offlineData.uniqueId = uuidv4();
            offlineData.isAcknowledged = false;

            const dbs = await openDB('order', 1, {
              upgrade(db) {
                // Create a store of objects
                const store = db.createObjectStore('order_requests', {
                  // The 'uniqueId' property of the object will be the key.
                  keyPath: 'uniqueId',
                  // If it isn't explicitly set, create a value by auto incrementing.
                  // autoIncrement: true,
                });
                // Create an index on the 'date' property of the objects.
                store.createIndex('uniqueId', 'uniqueId');
              },
            });
            await dbs.add('order_requests', offlineData);
            toast.dark(
              <Toaster
                icon="notify"
                message="Timesheet details successfully saved locally."
              />
            );
            setshowPrompt(false);
            route(`/viewTimesheet/${offlineData.uniqueId}`);
          } else {
            // is edit Mode
            // offlineData.uniqueId = timesheetData.uniqueId;

            const db = await openDB('order');
            await db.put('order_requests', offlineData);

            toast.dark(
              <Toaster
                icon="notify"
                message="Timesheet details updated and successfully saved locally."
              />
            );
            setshowPrompt(false);
            route(`/viewTimesheet/${timesheetId}`);
          }
        }
        if (isAddMode && isOnline) {
          // generate uuid for adding a new timesheet only
          timesheetBody.uniqueId = uuidv4();
          timesheetBody.isAcknowledged = false;
          const response = await trackPromise(createTimesheet(timesheetBody));
          if (response.status === 201) {
            toast.dark(
              <Toaster icon="notify" message={response.data.message} />
            );
            setshowPrompt(false);
            route(
              `/viewTimesheet/${response.data.data.timesheet.timesheetId}`
            );
          }
        } else if (!isAddMode && isOnline) {
          const response = await trackPromise(
            updateTimesheetById(timesheetId, timesheetBody)
          );
          if (response.status === 200) {
            toast.dark(
              <Toaster icon="notify" message={response.data.messagee} />
            );
            setshowPrompt(false);
            route(
              `/viewTimesheet/${response.data.data.timesheet.timesheetId}`
            );
          }
        }
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  };

  async function getTimesheetDetails() {
    try {
      if (isOnline) {
        const response = await trackPromise(
          getTimesheetByTimesheetId(timesheetId)
        );

        const { timesheet } = response.data.data;
        setTimesheet(timesheet);

        const ratePlanObj = getRatePlans(timesheet.choose_rate);

        reset(
          { ...response.data.data.timesheet, choose_rate: ratePlanObj },
          {
            keepDirty: true,
          }
        );

        setCompiledForm({
          one: {
            employee: timesheet.employee,
            service_city: timesheet.service_city,
            client: timesheet.client,
          },
          two: {
            ts_date: timesheet.ts_date,
            arriveTime: timesheet?.arriveTime,
            departureTime: timesheet?.departureTime,
            driveTime: timesheet?.driveTime,
            totalTime: timesheet?.totalTime,
            trainer: timesheet.trainer,
            flexPayHours: timesheet?.flexPayHours,
          },
          three: {
            holding_fee_number: timesheet.holding_fee_number,
            tip: timesheet.tip,
            adjustment: timesheet?.adjustment,
            current_holding_fee: timesheet.current_holding_fee,
            choose_rate: ratePlanObj,
            non_billable_reason: {
              _id: timesheet.non_billable_reason,
            },
            rate: timesheet.rate,
            totalDue: timesheet.totalDue,
            comments: timesheet.adjustment,
          },
        });
        setIsAPICalled(true);
      } else {
        const db = await openDB('order');
        const timesheetDetails = await db.get(
          'order_requests',
          IDBKeyRange.only(timesheetId)
        );
        setTimesheet(timesheetDetails);

        // setting data to timesheet
        const ratePlanObj = getRatePlans(timesheetDetails.choose_rate);

        reset(
          { ...timesheetDetails, choose_rate: ratePlanObj },
          {
            keepDirty: true,
          }
        );

        setCompiledForm({
          one: {
            employee: timesheetDetails.employee,
            service_city: timesheetDetails.service_city,
            client: timesheetDetails.client,
          },
          two: {
            ts_date: timesheetDetails.ts_date,
            arriveTime: timesheetDetails?.arriveTime,
            departureTime: timesheetDetails?.departureTime,
            driveTime: timesheetDetails?.driveTime,
            totalTime: timesheetDetails?.totalTime,
            trainer: timesheetDetails.trainer,
            flexPayHours: timesheetDetails?.flexPayHours,
          },
          three: {
            holding_fee_number: timesheetDetails.holding_fee_number,
            tip: timesheetDetails.tip,
            adjustment: timesheetDetails?.adjustment,
            current_holding_fee: timesheetDetails.current_holding_fee,
            choose_rate: ratePlanObj,
            non_billable_reason: {
              _id: timesheetDetails.non_billable_reason?._id,
            },
            rate: timesheetDetails.rate,
            totalDue: timesheetDetails.totalDue,
            comments: timesheetDetails.adjustment,
          },
        });

        setIsAPICalled(true);
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const showPaymentConfirmation = () => {
    handleShow();
  };

  const onPaymentStatusChange = async () => {
    try {
      if (Object.keys(dirtyFields).length) {
        const isStepValid = await trigger();
        if (isStepValid || isValid) {
          const timesheetBody = form;
          // one
          timesheetBody.employee = timesheetBody.employee._id;
          timesheetBody.service_city = timesheetBody?.service_city._id ?? null;
          timesheetBody.client = timesheetBody?.client?._id ?? null;

          timesheetBody.choose_rate = timesheetBody.choose_rate.label;
          timesheetBody.non_billable_reason =
            timesheetBody?.non_billable_reason?._id ?? null;

          // three
          // timesheetBody.totalDue = totalDue
          timesheetBody.payment_status = 'paid';

          const response = await trackPromise(
            updateTimesheetById(timesheetId, timesheetBody)
          );
          if (response.status === 200) {
            toast.dark(
              <Toaster icon="notify" message={response.data.messagee} />
            );
            setshowPrompt(false);
            route(
              `/viewTimesheet/${response.data.data.timesheet.timesheetId}`
            );
          }
        }
      } else {
        const body = {
          status: 'paid',
        };
        const response = await trackPromise(
          updatePaymentStatus(timesheetId, body)
        );
        if (response.status === 200) {
          toast.dark(<Toaster icon="notify" message={response.data.message} />);
          handleClose();
          setshowPrompt(false);
          route(
            `/viewTimesheet/${response.data.data.timesheet.timesheetId}`
          );
        }
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      handleClose();
      // setIsAPICalled(false);
      // getTimesheetDetails();
      // setActiveStep(2);
    }
  };

  const onDeleteTimesheet = async () => {
    try {
      if (isOnline) {
        const response = await trackPromise(deleteTimesheetById(timesheetId));
        toast.dark(
          <Toaster icon="notify" message={`${response.data.message}`} />,
          { autoClose: 3000, hideProgressBar: true }
        );
      } else {
        const db = await openDB('order');
        await db.delete('order_requests', IDBKeyRange.only(timesheetId));
        toast.dark(
          <Toaster
            icon="notify"
            message="Timesheet details removed from local data."
          />,
          { autoClose: 3000, hideProgressBar: true }
        );
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      setDeleteShow(false);
      route('/timesheet');
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      getTimesheetDetails();
    } else {
      setIsAPICalled(true);
    }
  }, [isAddMode]);

  useEffect(() => {
    if (Object.keys(dirtyFields).length) {
      setshowPrompt(true);
    } else {
      setshowPrompt(false);
    }
  }, [form, errors]);

  function getStepContent(step, formContent) {
    switch (step) {
      case 0:
        return (
          <BasicDetails
            {...{ formContent }}
            timesheetId={timesheetId}
            showCityError={showCityError}
            isAddMode={isAddMode}
          />
        );
      case 1:
        return (
          <DateTimeDetails {...{ formContent }} showTimeError={showTimeError} />
        );
      case 2:
        return (
          <ChargeDetails
            {...{ formContent }}
            isAddMode={isAddMode}
            paymentStatus={isAddMode ? 'pending' : timesheetData.payment_status}
            // onPaymentStatusChange={onPaymentStatusChange}
            showPaymentConfirmation={showPaymentConfirmation}
          />
        );
      default:
        return 'Unknown step';
    }
  }

  const onHandleBack = () => {
    route('/timesheet');
  }

  return isAPICalled ? (
    <div>
      <div className="sticky-page-header">
        <div className="container">
          <Desktop>
            <div className="container">
              <Row className="ml-1">
                <Col md="1">
                  <a
                    onKeyPress={() => { }}
                    role="link"
                    tabIndex="0"
                    onClick={onHandleBack}
                  >
                    <Image
                      className="imageArrow float-right"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                      fluid
                    />
                  </a>
                </Col>
                <Col md="9" sm="3">
                  {isAddMode ? (
                    <h5 className="mt-1">
                      <strong>New Timesheet</strong>
                    </h5>
                  ) : (
                    <>
                      <h5 className="mb-0">
                        <strong>Edit Timesheet</strong>
                      </h5>
                      {showTimesheetLastUpdated(
                        authUser.user.role.rolename
                      ) && (
                          <label className="txt-light-secondary">
                            Last Updated on {DateConvert(timesheetData.updatedAt)}
                          </label>
                        )}
                    </>
                  )}
                </Col>
                <Col md="1">
                  <Button
                    variant="default"
                    className="btn-ftc-border-primary"
                    onClick={handleReset}
                  >
                    <strong>Reset</strong>
                  </Button>
                </Col>
                {!isAddMode ? (
                  <Col md="1" className="p-0">
                    <div className="ml-1">
                      <NavDropdown
                        title={<Ellipses />}
                        id="collasible-nav-dropdown"
                      >
                        <NavDropdown.Item
                          onClick={handleDeleteShow}
                          disabled={
                            !checkTimesheetDeletable(
                              authUser.user.role.rolename,
                              authUser.user.role.permissions,
                              timesheetData.payment_status,
                              timesheetData.ts_date
                            )
                          }
                        >
                          Delete
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </div>
          </Desktop>
          <Mobile>
            <div className="container">
              <Row className="border-bottom">
                <Col xs="1" className="p-0">
                  <a
                    onKeyPress={() => { }}
                    role="link"
                    tabIndex="0"
                    onClick={onHandleBack}
                  >
                    <Image
                      className="imageArrowMobile ml-2"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                      fluid
                    />
                  </a>
                </Col>
                <Col xs="8">
                  {isAddMode ? (
                    <h5 className="m-auto">
                      <strong>New Timesheet</strong>
                    </h5>
                  ) : (
                    <>
                      <h5 className="m-auto">Edit Timesheet </h5>
                      {showTimesheetLastUpdated(
                        authUser.user.role.rolename
                      ) && (
                          <label className="txt-light-secondary  mx-auto">
                            Last Updated on {DateConvert(timesheetData.updatedAt)}
                          </label>
                        )}
                    </>
                  )}
                </Col>
                <Col xs="1">
                  <Image
                    onClick={handleReset}
                    className="ftc-logo-24  mt-1"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-reset.svg`}
                  />
                </Col>
                {!isAddMode ? (
                  <>
                    <Col xs="1">
                      <div>
                        <NavDropdown
                          title={<Ellipses />}
                          id="collasible-nav-dropdown"
                        >
                          <NavDropdown.Item
                            onClick={handleDeleteShow}
                            disabled={
                              !checkTimesheetDeletable(
                                authUser.user.role.rolename,
                                authUser.user.role.permissions,
                                timesheetData.payment_status,
                                timesheetData.ts_date
                              )
                            }
                          >
                            Delete
                          </NavDropdown.Item>
                        </NavDropdown>
                      </div>
                    </Col>
                  </>
                ) : (
                  ''
                )}
              </Row>
            </div>
          </Mobile>
        </div>
      </div>
      <Desktop>
        <div className="container-top-8" />
      </Desktop>
      <Mobile>
        <div className="container-top-20" />
      </Mobile>
      <div className="container">
        <div className={classes.root}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>
            {/* {activeStep === steps.length ? 
                    (
                    <div>
                        <Typography className={classes.instructions}>
                        All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} className={classes.button}>
                            Reset
                        </Button>
                    </div>
                    ) :
                    ( */}
            <FormProvider {...methods}>
              <Form
              // ref={myForm}
              // onSubmit={handleSubmit(onSubmitForm)}>
              >
                <div>
                  <Typography className={classes.instructions}>
                    {getStepContent(activeStep, compiledForm)}
                  </Typography>
                  <div>
                    <Row className="mx-auto">
                      <Col md="8" className="mx-auto pr-0 pl-2">
                        {activeStep !== 0 ? (
                          <Button
                            variant="default"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className="btn-ftc-border-primary"
                          >
                            Back
                          </Button>
                        ) : (
                          ''
                        )}

                        {activeStep === steps.length - 1 ? (
                          <button
                            type="button"
                            className={`${classes.button} float-right btn btn-ftc-primary`}
                            onClick={handleSubmit(onSubmitForm)}
                            disabled={isSubmitting}
                          // type="submit"
                          >
                            Submit
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={handleNext}
                            className={`${classes.button} float-right btn btn-ftc-primary`}
                          >
                            Continue
                          </button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
                {/* )} */}
              </Form>
            </FormProvider>
          </div>
        </div>
      </div>

      {show && (
        <ConfirmAlertModal
          className="payment__modal"
          show={show}
          handleClose={handleClose}
          title="Mark Payment as Paid."
          description="Please note that this action is not reversible. Once the payment has been marked Paid, it can not be changed."
          callback={onPaymentStatusChange}
          action="Mark as Paid"
          closeAction="Cancel"
        />
      )}

      {showDelete && (
        <ConfirmAlertModal
          show={showDelete}
          handleClose={handleDeleteClose}
          title="Delete Timesheet"
          description="Deleting this timesheet will erase all data inside this record.
                Are you sure you want to delete this timesheet?"
          callback={onDeleteTimesheet}
          action="Delete it"
          closeAction="Cancel"
        />
      )}

      <Prompt
        // when={Object.keys(dirtyFields).length > 0}
        when={showPrompt}
        message="You have unsaved changes, are you sure you want to leave?"
      />
    </div>
  ) : (
    ''
  );
};

export default EditTimeSheet;

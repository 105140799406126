export const limitDecimalPlaces = (e, count) => {
    if (e.target.value.indexOf('.') === -1) {
        return;
    }
    if (e.target.value.length - e.target.value.indexOf('.') > count) {
        e.target.value = parseFloat(e.target.value).toFixed(count);
    }
};

export const isObjectEmpty = (obj) => {
    return Object.values(obj).every((value) => {
        if (value && typeof value === "object") {
            return Object.values(value).every((nestedValue) => !nestedValue);
        }
        return !value;
    });
};

// Key press handler function to prevent 'e' or 'E'
export const handleKeyDown = (e) => {
    if (
        e.key === 'e' ||
        e.key === 'E' ||
        e.keyCode === 69 || // Prevent 'e' (scientific notation)
        (e.keyCode === 189 && e.target.value.includes('-')) // Prevent multiple '-' signs
    ) {
        e.preventDefault();
    }
};
const API_ENDPOINTS = {
  // baseURL: 'http://54.200.82.8:4000',
  // baseURL: `http://${window.location.hostname}:4000`,
  // baseURL: 'https://apis.ftc-dev.solvative.dev',
  // baseURL: 'https://api-app.weeklymealprep.com',
  // baseURL: 'https://apis.ftc-testing.solvative.dev'
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:4000',
  AUTH: {
    LOGIN: '/auth/signin',
    GoogleLogin: '/auth/googlelogin',
  },
  USERS: {
    Users: '/user',
    AllUsersByName: '/user/usersByName',
    AllChefsByName: 'user/chefNames',
    GetPassword: '/user/userPassword',
    UsersByCity: '/user/usersByCity',
    PresignedUrl: '/user/presignedUrl',
    GetUserFlexPayLimit: '/user/getUserFlexPayLimit',
    UpdateUserFlexPayLimit: '/user/updateUserFlexPayLimit',
  },
  ROLES: {
    Roles: '/role',
    AllRolesByName: '/role/rolesByName',
  },
  CITIES: {
    Cities: '/city',
    AllCitiesByName: '/city/citiesByName',
    AllCitiesForReport: '/city/citiesForReport',
  },
  REIMBURSEMENT: {
    Reimbursements: '/reimbursement',
    UpdatePaymentStatus: '/reimbursement/changePaymentStatus',
    PresignedUrl: '/reimbursement/presignedUrl',
    BenefitHours: '/reimbursement/benefitHours',
    ReimbursementsByEmployeeId: '/reimbursement/reimbursementsByEmployeeId',
    ReimbursementsByPeriods: '/reimbursement/reimbursementsByPeriods',
  },
  CLIENTS: {
    Clients: '/client',
    ClientsByChef: '/client/clientByChef',
    ClientsByCity: '/client/clientByCity',
  },
  TIMESHEETS: {
    Timesheets: '/timesheet',
    UpdatePaymentStatus: '/timesheet/changePaymentStatus',
  },
  HOURS_CATEGORIES: {
    HoursCategories: '/hours_category',
  },
  NON_BILLABLES: {
    NonBillables: '/non_billable',
    NonBillablesByName: '/non_billable/nonBillableByName',
  },
  REIMBURSEMENT_REASONS: {
    ReimbursementReasons: '/reimbursement_reason',
    ReimbursementReasonByName: '/reimbursement_reason/reimbursementReasonByName',
    ReimbursementAccessible: '/reimbursement_reason/reimbursementAccessible'
  },
  HOURS_REPORTS: {
    HoursReport: '/hours_report',
  },
  PAYROLL_REPORTS: {
    PayrollReport: './payroll_report',
  },
  PERIOD: {
    GetAllPeriod: '/period',
    GetPeriodByDate: '/period/periodByDate',
    GetAllPeriodsForSelect: '/period/getAll',
    GetAllPeriodYears: '/period/getAllPeriodYears'
  },
  CUSTOM_PAGES: {
    CustomPage: '/custom_page',
    ByLocation: '/custom_page/byLocation',
    GetAllPages: '/custom_page/getAllPages',
  },
  CUSTOM_POPUPS: {
    CustomPopup: '/custom_popup',
    ByLocation: '/custom_popup/byLocation',
    GetAllPopups: '/custom_popup/getAllPages',
  },
  PAYMENT: {
    Verify: 'payment/verifyPayment',
  },
};

export default API_ENDPOINTS;

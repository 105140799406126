import DateFnsUtils from '@date-io/date-fns';
import { TablePagination } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React, { memo, useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  Image,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';
import { Clear } from '@material-ui/icons';
import exportIcon from '../../assets/images/exportIcon.svg';
import ConfirmAlertModal from '../../components/ConfirmAlertModal/ConfirmAlertModal';
import DataTableComponent from '../../components/DataTableComponent';
import NoCustomPageComponent from '../../components/NoCustomPageComponent/NoCustomPageComponent';
import RenderCustomPopup from '../../components/RenderCustomPopup';
import TablePaginationActions from '../../components/TablePaginationActions/TablePaginationActions';
import Toaster from '../../components/Toaster';
import { useAuthContext } from '../../contexts/user-context';
import {
  getAllPeriodYears,
  getPeriodsByYear,
} from '../../services/period-service';
import {
  deleteReimbursementById,
  getAllReimbursements,
} from '../../services/reimbursement-service';
import {
  DateConvert,
  getAcknowledgeStatus,
  getPaymentStatusLabel,
  showExportButton,
} from '../../utils/TimesheetManagement';
import { validatePermission } from '../../utils/UserManagement';
import { Desktop, Mobile } from '../media-queries/mediaQueries';
import TableMobileView from './mobile-layouts/ReimbursementMobileTable';
// import TablePaginationActions from '../../../components/TablePaginationActions/TablePaginationActions';
import Loader from '../../assets/images/loader.svg';
import DatePickerInput from '../../components/DatePickerInput/DatePickerInput';
import constant from '../../constant';
import { getAllReimbursementReasons } from '../../services/reimbursement_reason.services';
import "../../styles/components/_custom-filter-select-dropdown.scss";
import { exportToCSV } from '../../utils/ExportDataToCSV';
import Sidebar from '../side-navbar/Sidebar';
import ReimbursementFilters from './reimbursementFilters';
import CustomButton from '../../components/Buttons';
import { showSearchButton } from '../../utils/ReimbursementManagement';
import { handleKeyDown, isObjectEmpty, limitDecimalPlaces } from '../../utils/helper';

const DEFAULT_VALUE = '-';

const reimbursement = memo(() => {
  const { authUser } = useAuthContext();
  const history = useHistory();
  const apiCallRef = useRef(false);
  const initialCallRef = useRef(false);
  // const { scroller } = Scroll;
  const animatedComponents = makeAnimated();
  const isIndeterminate = (indeterminate) => indeterminate;
  const selectableRowsComponentProps = { indeterminate: isIndeterminate };
  const [isAPICalled, setAPICalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reimbursementCount, setReimbursementCount] = useState({
    totalCount: 0,
    filterCount: 0,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [reimbursements, setReimbursements] = useState([]);

  const [employee, setEmployee] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const [allYears, setAllYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);

  const [periods, setPeriods] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [isPeriodLoading, setIsPeriodLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [showConfirmAlert, setConfirmAlert] = useState(false);
  const [exportAPICalled, setExportAPICalled] = useState(false);
  const [reimbursementReasons, setReimbursementReasons] = useState([]);
  const [selectedReimbursementReason, setSelectedReimbursementReason] = useState(null);
  const [selectedSubmissionDate, setSelectedSubmissionDate] = useState({
    startDate: null,
    endDate: null,
  });

  const [minAmount, setMinAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState('');

  // Modal
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => setOpenModal(false);

  const handleCloseConfirm = () => setConfirmAlert(false);

  const [filterState, setFilterState] = useState({
    period: {},
    reimbursementReason: '',
    submissionDate: { startDate: null, endDate: null },
    searchText: '',
  });

  const [reqQuery, setReqQuery] = useState({
    period: "",
    periodYear: '',
    submissionDate: { startDate: null, endDate: null },
    reimbursementReason: '',
    totalAmount: { minAmount: '', maxAmount: '' }
  })

  const [isClearAllDisabled, setIsClearAllDisabled] = useState(false);

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.employee?.username || DEFAULT_VALUE,
      sortable: true,
    },
    {
      name: 'Reason',
      selector: (row) => row?.reason?.reason || DEFAULT_VALUE,
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) => DateConvert(row.reimbursement_date),
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row) => `$${row?.total_amount}`,
      sortable: true,
    },
    {
      name: 'Status',
      minWidth: '120px',
      cell: (row) => {
        // const status = 'active'
        const { reimbursement_status: rstatus } = row;

        return (
          // eslint-disable-next-line react/destructuring-assignment
          <div title={row?.reimbursementAcknowledgedBy?.username}>
            <label
              className={
                rstatus === 'pending'
                  ? 'label-pending'
                  : rstatus === 'denied'
                    ? 'label-deny'
                    : 'label-active'
              }
            >
              <span
                className={
                  // eslint-disable-next-line no-nested-ternary
                  rstatus === 'pending'
                    ? 'pending-ellipse'
                    : rstatus === 'denied'
                      ? 'deny-ellipse'
                      : 'active-ellipse'
                }
              />
              {` `}
              {getAcknowledgeStatus(rstatus)}
            </label>
          </div>
        );
      },
    },
    {
      name: 'Payment',
      minWidth: '120px',
      cell: (row) => {
        // const status = 'active'
        const { payment_status: status } = row;
        return (
          // eslint-disable-next-line react/destructuring-assignment
          <div title={row?.markAsPaidBy?.username}>
            <label
              className={
                status === 'pending' ? 'label-pending' : 'label-active'
              }
            >
              <span
                className={
                  status === 'pending' ? 'pending-ellipse' : 'active-ellipse'
                }
              />
              {` `}
              {getPaymentStatusLabel(status)}
            </label>
          </div>
        );
      },
    },
  ];

  const route = (path) => {
    history.push({
      pathname: path,
    });
  }

  const handleRowClicked = (row) => {
    route(`/viewReimbursement/${row.reimbursementId}`);
  };

  const onAddNew = () => {
    route('/addNewReimbursement');
    // history.push(`/viewReimbursement/1`);
  };

  const handleViewClick = (state) => {
    route(`/viewReimbursement/${state.target.id}`);
  };

  const handleClickDelete = (state) => {
    setSelectedRow(state.target.id);
    setConfirmAlert(true);
  };

  const storeReimbursementFilter = (reqQueryData, filterStateData) => {
    localStorage.setItem("reimbursement-filter", JSON.stringify(reqQueryData));
    localStorage.setItem("reimbursement-filter-period", JSON.stringify(filterStateData));
  };

  async function fetchAllReimbursements(reqQueryData, filterStateData, manualTrigger = false, rows = 10, pages = 0, search) {
    if (!manualTrigger && apiCallRef.current) return;
    setLoading(true);
    try {
      let str;
      if (reqQueryData && filterStateData) {
        str = JSON.stringify(reqQueryData);

        storeReimbursementFilter(reqQueryData, filterStateData);
      } else {
        str = JSON.stringify(reqQuery);
      }

      const searchText = search ?? filterStateData?.searchText ?? employee;
      const queryString = `&filter=${str}`;

      const response = await trackPromise(
        getAllReimbursements(rows, pages, searchText, queryString)
      );

      // Update state with response data
      setReimbursements(response.data.data.reimbursements);
      setReimbursementCount({
        totalCount: response.data.data.totalCount,
        filterCount: response.data.data.filterCount,
      });
      setAPICalled(true);
      setIsClearAllDisabled(isObjectEmpty(reqQueryData) && isObjectEmpty({ employee: searchText }));

      apiCallRef.current = true;
      initialCallRef.current = false;

    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'Something went wrong!!!';
      toast.dark(<Toaster icon="error" message={errorMessage} />);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    let filters; let filterPeriod

    const initialFilters = { ...reqQuery };
    const initialFilterState = { ...filterState };
    const localFilters = JSON.parse(localStorage.getItem('reimbursement-filter'));
    const localFilterState = JSON.parse(localStorage.getItem('reimbursement-filter-period'));

    if (localFilters && localFilterState) {
      filters = { ...localFilters };
      filterPeriod = { ...localFilterState };
    } else {
      filters = { ...initialFilters };
      filterPeriod = { ...initialFilterState };
    }
    if (filterPeriod.searchText === undefined) {
      filterPeriod.searchText = employee || '';
    }
    setReqQuery({ ...filters });
    setFilterState({ ...filterPeriod });
    setSelectedYear(filters.periodYear);
    setEmployee(filterPeriod.searchText);
    if (filterPeriod.period && Object.keys(filterPeriod.period).length > 0) {
      setSelectedPeriod(filterPeriod.period);
    } else {
      setSelectedPeriod(null);
    }
    setSelectedReimbursementReason(filterPeriod.reimbursementReason);
    setSelectedSubmissionDate({
      startDate: filterPeriod.submissionDate.startDate,
      endDate: filterPeriod.submissionDate.endDate,
    });
    setMinAmount(filters.totalAmount.minAmount);
    setMaxAmount(filters.totalAmount.maxAmount);

    if (filters && filterPeriod) {
      apiCallRef.current = false;
      initialCallRef.current = true;
      fetchAllReimbursements(filters, filterPeriod, true, rowsPerPage, page);
    } else {
      storeReimbursementFilter(reqQuery, filterState);
    }
  }, []);

  useEffect(() => {
    if (initialCallRef.current) return;
    fetchAllReimbursements(reqQuery, filterState, true, rowsPerPage, page, employee);
  }, [rowsPerPage, page]);

  const onSearchClick = () => {
    setPage(0);
    setSearchQuery(employee);
    setFilterState(prevState => ({
      ...prevState,
      searchText: employee,
    }))
    filterState.searchText = employee;
    fetchAllReimbursements(reqQuery, filterState, true, rowsPerPage, 0, employee);
  };

  const handleClearSearch = () => {
    setEmployee('');
    setSearchQuery('');
    setFilterState(prevState => ({
      ...prevState,
      searchText: ''
    }))
    setPage(0);
    apiCallRef.current = false;
    fetchAllReimbursements(reqQuery, filterState, true, rowsPerPage, 0, '');
  };

  const resetPeriod = () => {
    setSelectedPeriod(null);
    setReqQuery(prev => ({
      ...prev,
      period: "",
    }))
    setFilterState(prevState => ({
      ...prevState,
      period: {}
    }))
  };

  const resetReimbursementReason = () => {
    setFilterState(prevState => ({
      ...prevState,
      reimbursementReason: '',
    }))
    setReqQuery(prev => ({
      ...prev,
      reimbursementReason: '',
    }))
  };

  async function fetchAllReimbursementReasons() {
    try {
      const response = await trackPromise(
        getAllReimbursementReasons(rowsPerPage, page, '')
      );

      setReimbursementReasons(response.data.data.reimbursement_reasons);
      setAPICalled(true);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  };

  async function fetchAllPagesReimbursements() {
    try {
      setExportAPICalled(true);
      const str = JSON.stringify(reqQuery);
      const queryString = `&filter=${str}`;
      const searchText = filterState?.searchText ?? employee;
      const response = await trackPromise(
        getAllReimbursements(reimbursementCount.totalCount, 0, searchText, queryString)
      );
      const transformedData = response.data.data.reimbursements.map(item => ({
        Name: item.employee?.username || "N/A",
        Reason: item.reason?.reason || "N/A",
        date: DateConvert(item.reimbursement_date),
        Amount: item.total_amount,
        reimbursementStatus: item.reimbursement_status,
        paymentStatus: item.payment_status,
      }));
      exportToCSV(transformedData, `reimbursement_data_${DateConvert(new Date())}`);
      setExportAPICalled(false);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
      setExportAPICalled(false);
    }
  };

  const onDeleteReimbursement = async () => {
    try {
      const response = await trackPromise(deleteReimbursementById(selectedRow));
      toast.dark(
        <Toaster icon="notify" message={`${response.data.message}`} />,
        { autoClose: 3000, hideProgressBar: true }
      );
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      setConfirmAlert(false);
      apiCallRef.current = false;
      fetchAllReimbursements(reqQuery, filterState, true);
      // history.replace('/reimbursement');
    }
  };

  // const onMenuOpen = (elementName) => {
  //   scroller.scrollTo(elementName, {
  //     duration: 1500,
  //     delay: 100,
  //     smooth: true,
  //     offset: 50, // Scrolls to element + 50 pixels down the page
  //   });
  // };

  async function fetchAllYears() {
    try {
      const response = await trackPromise(getAllPeriodYears());
      setAllYears(response.data.data.periods);
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  async function fetchAllPeriods(year) {
    setIsPeriodLoading(true);
    try {
      let response;
      if (year?.year || selectedYear?.year) {
        response = await trackPromise(
          getPeriodsByYear(year.year ?? selectedYear.year, new Date())
        );
      }

      const totalPeriods = response.data.data.periods.length;

      if (totalPeriods) {
        setPeriods(
          response.data.data.periods.map((period, index) => {
            const tempPeriod = { ...period };
            tempPeriod.label = `Period ${totalPeriods - index} (${DateConvert(
              period.start_date
            )} - ${DateConvert(period.end_date)})`;
            tempPeriod.value = period.period_index;
            return tempPeriod;
          })
        );
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      setIsPeriodLoading(false);
    }
  }

  const handleOpen = () => {
    setOpenModal(true);

    // Retrieve filters from localStorage
    const filters = JSON.parse(localStorage.getItem('reimbursement-filter'));
    const filterPeriod = JSON.parse(localStorage.getItem('reimbursement-filter-period'));

    // Set state
    setSelectedYear(filters.periodYear);

    setSelectedReimbursementReason(filterPeriod.reimbursementReason);
    setSelectedSubmissionDate({ startDate: filterPeriod.submissionDate.startDate, endDate: filterPeriod.submissionDate.endDate });
    setMinAmount(filters.totalAmount.minAmount);
    setMaxAmount(filters.totalAmount.maxAmount);

    setReqQuery(prev => ({
      ...prev,
      period: filters.period,
      periodYear: filters.periodYear,
      reimbursementReason: filters.reimbursementReason,
      submissionDate: filters.submissionDate,
      totalAmount: filters.totalAmount,
    }));

    setFilterState(prev => ({
      ...prev,
      period: filterPeriod.period,
      reimbursementReason: filterPeriod.reimbursementReason,
      submissionDate: filterPeriod.submissionDate,
    }))

    // Handle periods logic
    if (filters.periodYear) {
      fetchAllPeriods(filters.periodYear);
      if (filterPeriod.period && Object.keys(filterPeriod.period).length > 0) {
        setSelectedPeriod(filterPeriod.period);
      } else {
        setSelectedPeriod(null);
      }
    } else {
      setSelectedPeriod(null);
      setPeriods([]);
    }
  };

  const handleResetFilters = (filter) => {
    switch (filter) {
      case 'period':
        setSelectedYear(null);
        setSelectedPeriod(null);
        setPeriods([]);
        break;

      case 'reimbursementReason':
        setSelectedReimbursementReason(null);
        break;

      case 'submissionDate':
        setSelectedSubmissionDate({ startDate: null, endDate: null });
        break;
      case 'totalAmount':
        setMinAmount('');
        setMaxAmount('');
        break;

      default:
        break;
    }
  }

  const handleApplyFilters = () => {
    if (selectedSubmissionDate.startDate || selectedSubmissionDate.endDate) {
      if (!selectedSubmissionDate.startDate || !selectedSubmissionDate.endDate) {
        toast.dark(
          <Toaster
            icon="error"
            message="Select both Submission dates"
          />
        );
        return;
      }
    }

    if (minAmount && maxAmount) {
      const minimumAmount = Number(minAmount);
      const maximumAmount = Number(maxAmount);
      if (minimumAmount > maximumAmount) {
        toast.dark(
          <Toaster
            icon="error"
            message="Minimum amount should be lesser than maximum amount."
          />
        );
        return;
      }
    }

    handleClose();

    const updatedQuery = { ...reqQuery };
    const updatedFilters = { ...filterState };

    // Period Year
    if (selectedYear) {
      fetchAllPeriods(selectedYear);
      updatedQuery.periodYear = selectedYear;
    } else {
      updatedQuery.periodYear = "";
    }

    // Period
    if (selectedPeriod) {
      updatedFilters.period = selectedPeriod;
      updatedQuery.period = selectedPeriod._id;
    } else {
      updatedQuery.period = ""
      updatedFilters.period = {};
    }

    // Submission Date Range
    if (selectedSubmissionDate.startDate && selectedSubmissionDate.endDate) {
      const startDate = new Date(selectedSubmissionDate.startDate);
      const endDate = new Date(selectedSubmissionDate.endDate);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      updatedQuery.submissionDate = {
        startDate: moment(startDate).utc().format(),
        endDate: moment(endDate).utc().format()
      };
    } else {
      updatedQuery.submissionDate = { startDate: null, endDate: null };
    }

    // Reimbursement Reason
    if (selectedReimbursementReason) {
      updatedQuery.reimbursementReason = selectedReimbursementReason._id;
      updatedFilters.reimbursementReason = selectedReimbursementReason;
    } else {
      updatedQuery.reimbursementReason = '';
      updatedFilters.reimbursementReason = '';
    }

    // Amount
    updatedQuery.totalAmount = {};
    if (minAmount) {
      updatedQuery.totalAmount.minAmount = minAmount;
    }

    if (maxAmount) {
      updatedQuery.totalAmount.maxAmount = maxAmount;
    }

    if (!minAmount || !maxAmount) {
      updatedQuery.totalAmount.minAmount = minAmount;
      updatedQuery.totalAmount.maxAmount = maxAmount;
    }

    setReqQuery(updatedQuery);
    setFilterState(updatedFilters);
    setPage(0);
    apiCallRef.current = false;
    fetchAllReimbursements(updatedQuery, updatedFilters, true, rowsPerPage, 0);
  };

  const resetFilters = () => {
    const commonResetData = {
      period: "",
      periodYear: '',
      submissionDate: { startDate: null, endDate: null },
      reimbursementReason: '',
      totalAmount: { minAmount: '', maxAmount: '' }
    };

    const commonFilterStateData = {
      period: {},
      reimbursementReason: '',
      submissionDate: { startDate: null, endDate: null },
      searchText: filterState.searchText,
    };

    // Reset state variables
    setReqQuery(commonResetData);
    setFilterState(commonFilterStateData);
    setSelectedYear(null);
    setSelectedPeriod(null);
    setSelectedReimbursementReason(null);
    setSelectedSubmissionDate({ startDate: null, endDate: null });
    setMinAmount('');
    setMaxAmount('');
    return { commonResetData, commonFilterStateData };
  };

  const handleClearFilters = () => {
    const { commonResetData, commonFilterStateData } = resetFilters();
    setPage(0);
    apiCallRef.current = false;
    fetchAllReimbursements(commonResetData, commonFilterStateData, true, rowsPerPage, 0);
  }

  const handleClearAll = () => {
    const { commonResetData, commonFilterStateData } = resetFilters();
    commonFilterStateData.searchText = '';
    setEmployee('');
    setSearchQuery('');
    setPage(0);
    apiCallRef.current = false;
    fetchAllReimbursements(commonResetData, commonFilterStateData, true, rowsPerPage, 0, '');
  }

  const removeSelectedFilter = (filterName) => {

    const reqQueryValues = { ...reqQuery };
    const filterStateValues = { ...filterState };

    const resetState = (key, defaultValue) =>
      setReqQuery(prevState => ({
        ...prevState,
        [key]: defaultValue,
      }));

    const stateDefaults = {
      periodYear: '',
      period: "",
      submissionDate: { startDate: null, endDate: null },
      reimbursementReason: '',
      totalAmount: { minAmount: '', maxAmount: '' }
    };

    const resetPeriodValues = () => {
      setSelectedPeriod(null);
      setPeriods([]);
      reqQueryValues.period = "";
      filterStateValues.period = {};
    };

    switch (filterName) {
      case "periodYear":
        setSelectedYear(null);
        reqQueryValues.periodYear = '';
        resetState(filterName, stateDefaults[filterName]);
        resetPeriodValues();
        resetPeriod();
        break;

      case "period":
        resetPeriodValues();
        resetPeriod();
        break;

      case "submissionDate":
        setSelectedSubmissionDate({ startDate: null, endDate: null });
        reqQueryValues.submissionDate = { startDate: null, endDate: null };
        resetState(filterName, stateDefaults[filterName]);
        setFilterState(prevState => ({
          ...prevState,
          submissionDate: { startDate: null, endDate: null },
        }));
        break;

      case "reimbursementReason":
        setSelectedReimbursementReason(null);
        setFilterState(prevState => ({
          ...prevState,
          reimbursementReason: '',
        }));
        reqQueryValues.reimbursementReason = '';
        filterStateValues.reimbursementReason = '';
        resetState(filterName, stateDefaults[filterName]);
        break;

      case "totalAmount":
        setMinAmount('');
        setMaxAmount('');
        reqQueryValues.totalAmount = { minAmount: '', maxAmount: '' };
        resetState(filterName, stateDefaults[filterName]);
        break;

      default:
        return;
    }

    storeReimbursementFilter(reqQueryValues, filterStateValues);
    apiCallRef.current = false;
    setPage(0);
    fetchAllReimbursements(reqQueryValues, filterStateValues, true, rowsPerPage, 0);
  }

  const displaySelectedFilters = () => {
    const filters = JSON.parse(localStorage.getItem('reimbursement-filter'));
    const filterPeriod = JSON.parse(localStorage.getItem('reimbursement-filter-period'));
    if (!filters || !filterPeriod) return null;

    let amount;
    if (filters?.totalAmount?.minAmount) {
      amount = `${filters?.totalAmount?.minAmount} - Max`;
    }

    if (filters?.totalAmount?.maxAmount) {
      amount = `0 - ${filters?.totalAmount?.maxAmount}`;
    }

    if (filters?.totalAmount?.minAmount && filters?.totalAmount?.maxAmount) {
      amount = `${filters?.totalAmount?.minAmount} - ${filters?.totalAmount?.maxAmount}`
    }

    const filterItems = [
      {
        label: 'Period Year',
        value: filters?.periodYear?.year,
        filterName: 'periodYear',
      },
      {
        label: 'Period',
        value: filterPeriod?.period?.label,
        filterName: 'period',
      },
      {
        label: 'Submission Dates',
        value: (filterPeriod?.submissionDate?.startDate && filterPeriod?.submissionDate?.endDate)
          ? `${moment.utc(filterPeriod.submissionDate.startDate).format('MM/DD/YYYY')} - ${moment.utc(filterPeriod.submissionDate.endDate).format('MM/DD/YYYY')}`
          : '',
        filterName: 'submissionDate',
      },
      {
        label: 'Reason',
        value: filterPeriod?.reimbursementReason?.reason,
        filterName: 'reimbursementReason',
      },
      {
        label: 'Amount',
        value: amount,
        filterName: 'totalAmount',
      },
    ];

    const hasAnyValue = filterItems.some(item => item.value);

    return hasAnyValue ? (
      <Row className="group_filter">
        {filterItems.map(
          (item) =>
            item.value && (
              <span className="filter-label" key={item.label}>
                <span className="txt-light-secondary">{item.label}:</span> {item.value || '-'}{' '}
                <Image
                  className='cross-btn'
                  src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
                  fluid
                  onClick={() => removeSelectedFilter(item.filterName)}
                />
              </span>
            )
        )}

        <span>
          <Button
            variant="link"
            onClick={handleClearFilters}
            className="float-right txt-primary"
          >
            Clear Filter
          </Button>
        </span>
      </Row>
    ) : null;
  };

  const reimbursementFilters = (filterOption) => {
    switch (filterOption) {
      case 'periodYear':
        return (
          <Col lg="12" md="12" xs="12">
            <Form.Group controlId="formBasicEmail">
              <label>Period Year</label>
              <Select
                name="period_year_options"
                id="periodYearOptionScroll"
                isMulti={false}
                closeMenuOnSelect
                components={animatedComponents}
                options={allYears || []}
                value={selectedYear}
                getOptionLabel={(option) => option.year}
                getOptionValue={(option) => option.year}
                onChange={(option) => {
                  if (option === null) {
                    setSelectedYear(null);
                    setReqQuery((prevState) => ({
                      ...prevState,
                      periodYear: "",
                    }));
                  } else {
                    setSelectedYear(option);
                  }

                  resetPeriod();
                }}
                styles={{
                  menu: (base) => ({
                    ...base,
                    zIndex: 999, // Ensure it appears above other elements
                  }),
                }}
                className="custom-dropdown"
                menuPlacement="auto"
                classNamePrefix="select"
                placeholder="Select"
                menuShouldScrollIntoView={false}
              />
            </Form.Group>
          </Col>
        )

      case "period":
        return (
          <Col lg="12" md="12" xs="12">
            <Form.Group controlId="formBasicEmail">
              <label>Period</label>
              <Select
                name="period_options"
                id="periodOptionScroll"
                isMulti={false}
                closeMenuOnSelect
                components={animatedComponents}
                options={isPeriodLoading ? [] : periods || []}
                value={selectedPeriod}
                onChange={(option) => {
                  if (option === null) {
                    setSelectedPeriod(null);
                    resetPeriod();
                  } else {
                    setSelectedPeriod(option);
                  }
                }}
                isLoading={isPeriodLoading}
                styles={{
                  menu: (base) => ({
                    ...base,
                    zIndex: 999, // Ensure the dropdown appears above other elements
                  }),
                }}
                className="custom-dropdown"
                menuPlacement="auto"
                classNamePrefix="select"
                placeholder={isPeriodLoading ? "Loading..." : "Select"}
                menuShouldScrollIntoView={false}
              />
            </Form.Group>
          </Col>
        )

      case "date":
        return (
          <Row>
            <Col lg="6" md="12" xs="12" className='mb-3'>
              <label>From</label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePickerInput
                  rounded
                  selectedDate={selectedSubmissionDate.startDate}
                  handleDateChange={(val) => {
                    setSelectedSubmissionDate(prev => ({
                      ...prev,
                      startDate: val,
                    }));
                    setFilterState(prevState => ({
                      ...prevState,
                      submissionDate: {
                        startDate: val,
                        endDate: prevState.submissionDate.endDate
                      },
                    }));
                  }}
                  disabled={false}
                  allowClear
                />
              </MuiPickersUtilsProvider>
            </Col>
            <Col lg="6" md="12" xs="12">
              <label>To</label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePickerInput
                  rounded
                  selectedDate={selectedSubmissionDate.endDate}
                  handleDateChange={(value) => {
                    setSelectedSubmissionDate(prevState => ({
                      ...prevState,
                      endDate: value,
                    }));
                    setFilterState(prevState => ({
                      ...prevState,
                      submissionDate: {
                        startDate: prevState.submissionDate.startDate,
                        endDate: value,
                      },
                    }));
                  }}
                  disabled={false}
                  allowClear
                />
              </MuiPickersUtilsProvider>
            </Col>
          </Row>
        )

      case "reason":
        return (
          <Col lg="12" md="12" xs="12">
            <Form.Group controlId="formBasicEmail">
              <label>Reason</label>
              <Select
                name="period_options"
                id="periodOptionScroll"
                isMulti={false}
                closeMenuOnSelect
                components={animatedComponents}
                options={reimbursementReasons || []}
                getOptionLabel={(option) => option.reason}
                getOptionValue={(option) => option._id}
                value={selectedReimbursementReason}
                onChange={(option) => {
                  if (option === null) {
                    setSelectedReimbursementReason(null);
                    resetReimbursementReason();
                  } else {
                    setSelectedReimbursementReason(option)
                  }
                }}
                styles={{
                  // clearIndicator: (base) => ({
                  //   ...base,
                  //   cursor: 'pointer',
                  //   padding: '5px',
                  // }),
                  menu: (styles) => ({ ...styles, zIndex: 999 }),
                }}
                // isClearable={!!selectedReimbursementReason}
                classNamePrefix="select"
                menuShouldScrollIntoView
                menuPlacement="auto"
                placeholder="Select"
              />
            </Form.Group>
          </Col>
        )

      case "amount":
        return (
          <Row>
            <Col lg="6" md="12" xs="12">
              <Form.Group controlId="exampleForm.SelectCustomMin">
                <label>Enter Minimum Amount</label>
                <Form.Control
                  type="number"
                  min='0'
                  step='0.01'
                  placeholder="0"
                  value={minAmount}
                  onChange={(e) => setMinAmount(e.target.value)}
                  onKeyPress={handleKeyDown}
                  onInput={(e) => limitDecimalPlaces(e, 2)}
                />
              </Form.Group>
            </Col>
            <Col lg="6" md="12" xs="12">
              <Form.Group controlId="exampleForm.SelectCustomMax">
                <label>Enter Maximum Amount</label>
                <Form.Control
                  type="number"
                  min='0'
                  step='0.01'
                  placeholder="0"
                  value={maxAmount}
                  onChange={(e) => setMaxAmount(e.target.value)}
                  onKeyPress={handleKeyDown}
                  onInput={(e) => limitDecimalPlaces(e, 2)}
                />
              </Form.Group>
            </Col>
          </Row>
        )

      default:
        return (
          <Col>
            <div>
              Filters
            </div>
          </Col>
        )
    }
  };

  useEffect(() => {
    fetchAllYears();
    fetchAllReimbursementReasons();
  }, []);

  useEffect(() => {
    if (selectedYear) {
      fetchAllPeriods(selectedYear);
    } else {
      setSelectedPeriod(null);
      setPeriods([]);
    }
  }, [selectedYear]);

  return (
    <div>
      <Row className="mr-0">
        <Desktop>
          <Col md="2">
            <Sidebar />
          </Col>
        </Desktop>
        <Mobile>
          <Sidebar />
        </Mobile>
        {isAPICalled && !reimbursementCount.totalCount ? (
          <NoCustomPageComponent
            title="Reimbursements"
            onAddNew={onAddNew}
            addNewText='Start adding your first ”Reimbursement"'
            isAddNew={validatePermission(
              authUser.user.role.permissions,
              'reimbursement',
              'add'
            )}
          />
        ) : (
          <>
            <Desktop>
              <Col md="10">
                <div className="container">
                  <Row className="border-bottom sticky-dashboard-header">
                      <Col md={3} lg={3}>
                      <h3>
                        <strong>Reimbursement</strong>
                      </h3>
                    </Col>
                    <Col />
                      <Col md={1} lg={1} className="pl-0 d-flex pr-0">
                      <Button
                        variant="default"
                        className="d-flex flex-row justify-content-center mr-3 mb-4 pl-3 pr-3"
                        onClick={handleOpen}
                      >
                        <Image
                          className="mt-1"
                          src={
                            Object.values(reqQuery).some((value) =>
                              typeof value === 'object'
                                ? Object.values(value).some((v) => v)
                                : value
                            )
                              ? `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter-data.svg`
                              : `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter.svg`
                          }
                          fluid
                        />
                        <span className="pl-1">Filter</span>
                      </Button>

                      {/* Filter Modal */}
                      <ReimbursementFilters
                        show={openModal}
                        handleClose={handleClose}
                        clearAll={handleClearFilters}
                        reimbursementFilters={reimbursementFilters}
                        applyChanges={handleApplyFilters}
                          handleResetFilters={handleResetFilters}
                      />
                      </Col>
                      {showSearchButton(authUser.user.role.rolename) && (
                        <>
                          <Col md={4} lg={4} className="pl-0 d-flex pr-0">
                            <InputGroup className="mb-4">
                              <FormControl
                                placeholder="Search Employees"
                                aria-label="Employees"
                                aria-describedby="basic-addon1"
                                value={employee}
                                onChange={(e) => setEmployee(e.target.value)}
                                onKeyPress={(event) => {
                                  if (event.key === 'Enter') {
                                    onSearchClick();
                                    setSearchQuery(event.target.value);
                                    setFilterState({
                                      ...filterState,
                                      searchText: event.target.value,
                                    });
                                  }
                                }}
                              />
                              <InputGroup.Prepend>
                                <Button
                                  variant="default"
                                  className="btn-ftc-primary-light"
                                  onClick={() => {
                                    if (employee) {
                                      onSearchClick();
                                    }
                                  }}
                                >
                                  Search
                                </Button>
                              </InputGroup.Prepend>
                            </InputGroup>
                            <CustomButton
                              text="Clear All"
                              variant="link"
                              width={150}
                              height={38}
                              disabled={isClearAllDisabled}
                              onClick={() => {
                                handleClearAll();
                              }}
                              className="float-right txt-primary clear-all-btn-default"
                            />
                          </Col>
                        </>
                      )
                      }
                    {showExportButton(authUser.user.role.rolename) && (
                      <Col xl={1 / 2} className="mr-3">
                        <span className="float-left">
                          <button
                            type="button"
                            className="btn btn-ftc-primary-light-border float-right"
                            onClick={() => fetchAllPagesReimbursements()}
                          >
                            {exportAPICalled ? (
                              <>
                                <Image
                                  src={Loader}
                                  height="18px"
                                  width="18px"
                                />
                              </>
                            ) : (
                              <>
                                <Image src={exportIcon} /> Export
                              </>
                            )}
                          </button>
                        </span>
                      </Col>
                    )}

                    {validatePermission(
                      authUser.user.role.permissions,
                      'reimbursement',
                      'add'
                    ) && (
                        <div className="pr-4">
                          <button
                            type="button"
                            className="btn btn-ftc-primary float-right"
                            onClick={onAddNew}
                          >
                            Add New
                          </button>
                        </div>
                      )}
                  </Row>
                  <div className="container-top-7">
                    {loading ?
                      (
                          <Col className='display-selected-filter-loader'>
                            <Image
                              src={Loader}
                              height="35px"
                              width="35px"
                            />
                          </Col>

                      ) :
                      (displaySelectedFilters())
                    }
                    <div>
                      <Row className="pt-0">
                        <Col md="12" className="pr-0">
                          {isAPICalled ? (
                            <>
                              <DataTableComponent
                                // title="Movie List"
                                highlightOnHover
                                pointerOnHover
                                noHeader
                                columns={columns}
                                data={reimbursements}
                                pagination
                                selectableRowsComponentProps={
                                  selectableRowsComponentProps
                                }
                                paginationServer
                                paginationTotalRows={
                                  reimbursementCount.filterCount
                                }
                                handleRowClicked={handleRowClicked}
                                paginationPerPage={rowsPerPage}
                                onChangePage={(p) => {
                                  setPage(p - 1);
                                  // look for better solution later
                                  // setPage(p)
                                }}
                                onChangeRowsPerPage={(rws, pg) => {
                                  setRowsPerPage(rws);
                                  setPage(pg);
                                }}
                              />
                              <TablePagination
                                labelRowsPerPage="Rows per page:"
                                component="div"
                                count={Number.parseInt(
                                  reimbursementCount.filterCount,
                                  10
                                )}
                                page={page}
                                onChangePage={(event, newPage) => {
                                  setPage(newPage);
                                }}
                                defaultPage={0}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={(event) => {
                                  setRowsPerPage(
                                    parseInt(event.target.value, 10)
                                  );
                                  setPage(0);
                                }}
                                ActionsComponent={TablePaginationActions}
                              />
                            </>
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Desktop>
            <Mobile>
              <Col>
                <div className="container pr-0">
                  <Row className="border-bottom pr-0 sticky-dashboard-header-mobile">
                    <Col xs="9" className="ml-4">
                      <h3>Reimbursement</h3>
                    </Col>
                    <Col xs="2">
                      <Image
                        className="ftc-logo-40"
                        src={`${process.env.PUBLIC_URL}/assets/ftc-mobile-logo-2.svg`}
                      />{' '}
                    </Col>
                  </Row>
                  <div className="container-top-20">
                    <Row className="mobile-btn-view">
                      {showExportButton(authUser.user.role.rolename) && (
                        <div>
                          <button
                            type="button"
                            className="export-btn btn btn-block"
                            onClick={() => fetchAllPagesReimbursements()}
                          >
                            {exportAPICalled ? (
                              <>
                                <Image
                                  src={Loader}
                                  height="16px"
                                  width="18px"
                                  className="export-img"
                                />
                              </>
                            ) : (
                              <>
                                <Image src={exportIcon} />
                                Export
                              </>
                            )}
                          </button>
                        </div>
                      )}

                      <div>
                        <button
                          type="button"
                          className="btn btn-ftc-primary btn-block"
                          onClick={onAddNew}
                        >
                          Add New
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="filter-btn btn btn-block"
                          onClick={handleOpen}
                        >
                          <Image
                            className="mr-2"
                            src={
                              Object.values(reqQuery).some((value) =>
                                typeof value === 'object'
                                  ? Object.values(value).some((v) => v)
                                  : value
                              )
                                ? `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter-data.svg`
                                : `${process.env.PUBLIC_URL}/assets/svg-icons/icon-filter.svg`
                            }
                            fluid
                          />
                          Filter
                        </button>

                        {/* Filter Modal */}
                        <ReimbursementFilters
                          show={openModal}
                          handleClose={handleClose}
                          clearAll={handleClearFilters}
                          reimbursementFilters={reimbursementFilters}
                          applyChanges={handleApplyFilters}
                            handleResetFilters={handleResetFilters}
                        />
                      </div>
                    </Row>
                      {
                        showSearchButton(authUser.user.role.rolename) && (
                          <Row className="pt-0 pb-0">
                            <Col xs="12" className="pr-0 pl-0">
                              <InputGroup className="mb-0">
                                <Form.Control
                                  type="text"
                                  placeholder="Search"
                                  value={employee}
                                  onChange={(e) => setEmployee(e.target.value)}
                                  onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                      onSearchClick();
                                      setSearchQuery({
                                        ...searchQuery,
                                        employee: event.target.value,
                                      });
                                    }
                                  }}
                                />
                                {
                                  employee &&
                                  <InputGroup.Prepend>
                                    <Button
                                      variant="default"
                                    className="btn-ftc-primary-light px-2 py-1"
                                        onClick={() => {
                                          handleClearSearch();
                                        }}
                                      >
                                    <Clear />
                                    </Button>
                                  </InputGroup.Prepend>
                                }
                              </InputGroup>
                            </Col>
                          </Row>
                        )
                      }
                    <Row>
                      {reimbursements.map((reimbursementDetail) => {
                        return (
                          <Col
                            md={6}
                            key={reimbursementDetail._id}
                            className="pr-1 pl-1"
                          >
                            <TableMobileView
                              reimbursement={reimbursementDetail}
                              callback={handleRowClicked}
                              handleViewClick={handleViewClick}
                              handleClickDelete={handleClickDelete}
                              isEditable
                              isDeletable={false}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                    {isAPICalled && !reimbursementCount.filterCount && (
                      <div className="text-center">
                        <p>There are no records to display.</p>
                      </div>
                    )}
                    <Row>
                      <Col xs="12">
                        <TablePagination
                          className="mobile__pagination"
                          labelRowsPerPage=""
                          ActionsComponent={TablePaginationActions}
                          component="div"
                          count={Number.parseInt(
                            reimbursementCount.filterCount,
                            10
                          )}
                          page={page}
                          // onChangePage={handleChangePage}
                          onChangePage={(event, newPage) => {
                            setPage(newPage);
                          }}
                          defaultPage={0}
                          rowsPerPage={rowsPerPage}
                          onChangeRowsPerPage={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Mobile>
          </>
        )}
      </Row>
      {showConfirmAlert && (
        <ConfirmAlertModal
          show={showConfirmAlert}
          handleClose={handleCloseConfirm}
          title="Delete Reimbursement"
          description={constant.DELETE_REIMBURSEMENT_POPUP_MESSAGE}
          callback={onDeleteReimbursement}
          action="Delete it"
          closeAction="Cancel"
        />
      )}
      <RenderCustomPopup />
    </div>
  );
});

export default reimbursement;

import React, { memo, useEffect } from 'react';
import { Alert, Col, Form, Image, Row, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';

import moment from 'moment';

import { Controller, useFormContext } from 'react-hook-form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import DatePickerInput from '../../../components/DatePickerInput/DatePickerInput';
import { useAuthContext } from '../../../contexts/user-context';
import { limitDecimalPlaces } from '../../../utils/helper';

const UserWorkHistory = memo(({ formContent, isAddMode }) => {
  const {
    register,
    control,
    formState: { errors },
    reset,
  } = useFormContext();
  const { authUser } = useAuthContext();

  const isFtc = process.env.REACT_APP_PROJECT_NAME;

  useEffect(() => {
    reset({ ...formContent.two }, { errors: true });
  }, []);

  // useEffect(() =>
  // {
  //     if (!isAddMode && formContent.two?.work_history.termination_date)
  //     {
  //         console.log(formContent.two.work_history.termination_date);
  //         setTerminationDate(formContent.two.work_history.termination_date)
  //     }
  // }, [formContent]);

  return (
    <div className="container">
      <Row>
        <Col md="8" className="mx-auto border-bottom">
          <Row>
            <Col md="6">
              <Form.Group controlId="exampleForm.SelectCustom">
                <Row>
                  <Col md="12">
                    <label className="txt-light-secondary">Hire Date</label>
                  </Col>
                  <Col md="12">
                    <Controller
                      render={({ field: { ref, name, value, onChange } }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePickerInput
                            name={name}
                            ref={ref}
                            handleDateChange={(e) =>
                              onChange(
                                moment(e)
                                  .utc()
                                  .utcOffset(0)
                                  .set({
                                    hour: 0,
                                    minute: 0,
                                    second: 0,
                                    millisecond: 0,
                                  })
                                  .toDate()
                              )
                            }
                            selectedDate={value}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                      name="work_history.hire_date"
                      control={control}
                    />
                    {errors.work_history?.hire_date && (
                      <p className="text-danger">
                        {errors.work_history?.hire_date.message}
                      </p>
                    )}
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="exampleForm.SelectCustom">
                <Row>
                  <Col md="12">
                    <label className="txt-light-secondary">
                      Last Raise Date
                    </label>
                  </Col>
                  <Col md="12">
                    <Controller
                      render={({ field: { ref, name, value, onChange } }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePickerInput
                            name={name}
                            ref={ref}
                            handleDateChange={(e) =>
                              onChange(
                                moment(e)
                                  // .utc()
                                  // .utcOffset(0)
                                  .set({
                                    hour: 0,
                                    minute: 0,
                                    second: 0,
                                    millisecond: 0,
                                  })
                                  .toDate()
                              )
                            }
                            selectedDate={value}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                      name="work_history.last_raise_date"
                      control={control}
                    />
                    {errors.work_history?.last_raise_date && (
                      <p className="text-danger">
                        {errors.work_history?.last_raise_date.message}
                      </p>
                    )}
                  </Col>
                </Row>
              </Form.Group>
            </Col>

            {!isAddMode ? (
              <Col md="12">
                {/* <DropDown title="Drive Time (in minutes)"/> */}

                <Form.Group controlId="terminationDateId">
                  <Row>
                    <Col md="12">
                      <Form.Label className="txt-light-secondary">
                        Termination Date
                      </Form.Label>
                    </Col>
                    <Col md="6">
                      <Controller
                        render={({ field: { ref, name, value, onChange } }) => (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePickerInput
                              name={name}
                              ref={ref}
                              handleDateChange={(e) =>
                                onChange(
                                  moment(e)
                                    // .utc()
                                    // .utcOffset(0)
                                    .set({
                                      hour: 0,
                                      minute: 0,
                                      second: 0,
                                      millisecond: 0,
                                    })
                                    .toDate()
                                )
                              }
                              selectedDate={value}
                            // selectedDate={terminationDate}
                            // handleDateChange={setTerminationDate}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                        name="work_history.termination_date"
                        control={control}
                      />
                      {errors.work_history?.termination_date && (
                        <p className="text-danger">
                          {errors.work_history?.termination_date.message}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            ) : (
              ''
            )}

            <Col md="6">
              <Form.Group controlId="exampleForm.SelectCustom">
                <Row>
                  <Col md="12">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="txt-light-secondary">
                        Current Wage/Hour{' '}
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          {...register('work_history.wage_per_hr')}
                          type="number"
                          min="0"
                          step="0.01"
                          // defaultValue="0.00"
                          placeholder="0"
                          onInput={(e) => limitDecimalPlaces(e, 2)}
                          onKeyPress={(e) => {
                            // Prevent the entry of the character 'e'
                            if (e.key === 'e' || e.key === 'E') {
                              e.preventDefault();
                            }
                          }}
                        />
                      </InputGroup>
                      {errors.work_history?.wage_per_hr && (
                        <p className="text-danger">
                          {errors.work_history?.wage_per_hr.message}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="exampleForm.SelectCustom">
                <Row>
                  <Col md="12">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="txt-light-secondary">
                        Current Salary/Week{' '}
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          {...register('work_history.salary_per_wk')}
                          type="number"
                          min="0"
                          step="0.01"
                          // defaultValue="0.00"
                          placeholder="0"
                          onInput={(e) => limitDecimalPlaces(e, 2)}
                          onKeyPress={(e) => {
                            // Prevent the entry of the character 'e'
                            if (e.key === 'e' || e.key === 'E') {
                              e.preventDefault();
                            }
                          }}
                        />
                      </InputGroup>
                      {errors.salary_per_wk && (
                        <p className="text-danger">
                          {errors.work_history?.salary_per_wk.message}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            {
              isFtc && <>
                <Col md="6">
                  <Form.Group controlId="exampleForm.SelectCustom">
                    <Row>
                      <Col md="12">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label className="txt-light-secondary">
                            Equipment Reimbursement Limit{' '}
                          </Form.Label>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              {...register('work_history.equip_reimbursement_limit')}
                              type="number"
                              min="0"
                              step="0.01"
                              // defaultValue="0.00"
                              placeholder="0"
                              // onInput={(e) => limitDecimalPlaces(e, 2)}
                              onKeyPress={(e) => {
                                // Prevent the entry of the character 'e'
                                if (e.key === 'e' || e.key === 'E') {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </InputGroup>
                          {errors.equip_reimbursement_limit && (
                            <p className="text-danger">
                              {errors.work_history?.equip_reimbursement_limit.message}
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                {
                  authUser.user.role.rolename === 'Admin' && <>
                    <Col md="6">
                      <Form.Group controlId="exampleForm.SelectCustom">
                        <Row>
                          <Col md="12">
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label className="txt-light-secondary">
                                Flex Pay Hours Limit{' '}
                              </Form.Label>
                              <InputGroup>
                                <InputGroup.Prepend>
                                  <InputGroup.Text>Hrs</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  {...register('work_history.flex_pay_hours_limit')}
                                  type="number"
                                  min="0"
                                  step="0.01"
                                  // defaultValue="0.00"
                                  placeholder="0"
                                  // onInput={(e) => limitDecimalPlaces(e, 2)}
                                  onKeyPress={(e) => {
                                    // Prevent the entry of the character 'e'
                                    if (e.key === 'e' || e.key === 'E') {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </InputGroup>
                              {errors.flex_pay_hours_limit && (
                                <p className="text-danger">
                                  {errors.work_history?.flex_pay_hours_limit.message}
                                </p>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  </>
                }
              </>
            }
            <Col md="12">
              <br />
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label className="txt-light-secondary">Notes</Form.Label>
                <Form.Control
                  {...register('work_history.comments')}
                  as="textarea"
                  rows={4}
                />
              </Form.Group>
            </Col>
            <Col md="12">
              <Alert variant="success">
                <Col md="12">
                  <span>
                    <Image
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-check.svg`}
                      fluid
                    />
                    {` `}Active User
                  </span>
                </Col>
              </Alert>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
});

export default UserWorkHistory;

UserWorkHistory.propTypes = {
  formContent: PropTypes.any.isRequired,
  isAddMode: PropTypes.any.isRequired,
};

import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

const CustomButton = ({ text, className, onClick, variant = "link", type = "button", width, height, disabled = false }) => {
    return (
        <Button
            type={type}
            className={className}
            onClick={onClick}
            variant={variant}
            style={{ width, height }}
            disabled={disabled}
        >
            {text}
        </Button>
    );
};

CustomButton.propTypes = {
    text: PropTypes.string.isRequired,      // Text to display on the button
    className: PropTypes.string,           // Additional classes for styling
    onClick: PropTypes.func,               // Click handler
    variant: PropTypes.string,             // Variant (e.g., "primary", "secondary", "link")
    type: PropTypes.string,                // Button type (e.g., button, submit, reset)
};

CustomButton.defaultProps = {
    className: "",
    onClick: () => { },
    variant: "link",
    type: "button",
};

export default CustomButton;
